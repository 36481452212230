import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { pensFillLayerConfig, pensLineLayerConfig } from 'configs/map/common';
import { POLYGON_SOURCE } from 'constants/map';
import { MapObject } from 'interfaces';

export const Polygon: FC<MapObject> = memo(({ id = POLYGON_SOURCE, data }) => (
  <Source id={id} type="geojson" data={data}>
    <Layer {...pensFillLayerConfig} id={`${id}-fill`} />
    <Layer {...pensLineLayerConfig} id={`${id}-boundary`} />
  </Source>
));

Polygon.displayName = 'Polygon';
