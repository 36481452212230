import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MAP_ENTITY_PARAM_VALUES, mapEntityParams } from 'constants/entities';
import { FeatureTypes } from 'constants/map';
import { useAppDispatch } from 'hooks';
import { IMediaFile } from 'interfaces';
import { upsertEntityThunk } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { MapEntity, TPosition } from 'types';
import { PredefinedTemplate } from 'types/entities';

import { ObjectPreviewHeader } from 'components/Map/ObjectPreview/ObjectPreviewHeader';
import { ObjectPreviewTable } from 'components/Map/ObjectPreview/ObjectPreviewTable';
import { MediaGalleryModal } from 'components/MediaGalleryModal';
import {
  formatWgsCoords,
  formatXYCoords,
  getCoordinatesCenter,
  getFormattedDate,
  getMonospacedString,
} from 'utils';
import { convertWsgToSk42 } from 'utils/converterUtils';
import { getMapObjectEntityValues } from 'utils/entity';
import { getEntityParametersIdTitleMap } from 'utils/entity';

import { ObjectPreviewMedia } from '../ObjectPreview/ObjectPreviewMedia';
import { ObjectPreviewPopup } from '../ObjectPreview/ObjectPreviewPopup';
import { ObjectPreviewTitle } from '../ObjectPreview/ObjectPreviewTitle';

interface EntityPreviewProps {
  entity: MapEntity;
  mapObjectTemplate: PredefinedTemplate;
  onClose: () => void;
}

export const EntityPreview: FC<EntityPreviewProps> = ({
  entity,
  mapObjectTemplate,
  onClose,
}) => {
  const [mapEntity, setMapEntity] = useState<MapEntity>(entity);
  const [sk42Coordinates, setSk42Coordinates] = useState({ x: 0, y: 0, h: 0 });
  const [isMediaGalleryOpen, setMediaGalleryOpen] = useState(false);
  const isMapEntityModifiedRef = useRef(false);

  const dispatch = useAppDispatch();

  const parametersIdTitleMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        mapObjectTemplate.parameters,
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [mapObjectTemplate.parameters]
  );

  const mapEntityValues = getMapObjectEntityValues(
    parametersIdTitleMap,
    mapEntity.entity
  );

  const name = mapEntityValues.name || 'Нет имени';
  const type = mapEntityValues.type || 'Нет типа';
  const status = mapEntityValues.status || 'Неизвестен';
  const geometry = mapEntityValues.geometry || {
    type: 'Point',
    coordinates: [0, 0],
  };

  const date =
    getFormattedDate(mapEntityValues.date, 'DD.MM.YYYY', true) || 'Нет даты';

  const description = mapEntityValues.description || '';
  const media = mapEntityValues.media || [];

  const [mediaFiles, setMediaFiles] = useState<IMediaFile[]>(media);

  const wgsCoordinates: TPosition = useMemo(() => {
    if (!geometry || geometry.coordinates.length == 0) {
      return [0, 0];
    }

    switch (geometry.type) {
      case FeatureTypes.POINT:
        return geometry.coordinates as TPosition;
      case FeatureTypes.LINE:
        return getCoordinatesCenter(geometry.coordinates as TPosition[]);
      case FeatureTypes.POLYGON:
        return getCoordinatesCenter(geometry.coordinates[0] as TPosition[]);
      default:
        return [0, 0];
    }
  }, [geometry]);

  const handleUpdateMapEntity = async () => {
    if (!isMapEntityModifiedRef.current) {
      return;
    }

    const updatedEntity = {
      ...mapEntity.entity,
      parameters: {
        ...mapEntity.entity.parameters,
        [parametersIdTitleMap[mapEntityParams.MEDIA]]: {
          ...mapEntity.entity.parameters[
            parametersIdTitleMap[mapEntityParams.MEDIA]
          ],
          value: mediaFiles,
        },
      },
    };

    setMapEntity({ ...mapEntity, entity: updatedEntity });
    await dispatch(upsertEntityThunk(updatedEntity));
  };

  const handleMediaFileDrop = (media: IMediaFile[]) => {
    isMapEntityModifiedRef.current = true;

    setMediaFiles(media);
  };

  const handleDeleteMediaFile = (index: number) => {
    const deletedMediaFile = mediaFiles[index];

    if (!deletedMediaFile) {
      return;
    }

    isMapEntityModifiedRef.current = true;

    setMediaFiles((prevState) =>
      prevState.filter((mediaFile) => mediaFile.url !== deletedMediaFile.url)
    );
  };

  const handleMediaModalClose = async () => {
    await handleUpdateMapEntity();

    isMapEntityModifiedRef.current = false;

    setMediaGalleryOpen(false);
  };

  const descriptionRows = [
    { label: 'Статус', value: status },
    {
      label: 'Дата',
      value: date,
    },
    {
      label: 'Описание',
      value: description,
    },
  ];

  const locationRows = [
    {
      label: 'WGS',
      value: formatWgsCoords(wgsCoordinates[1], wgsCoordinates[0]),
      copyable: true,
    },
    {
      label: 'CK-42',
      value: formatXYCoords(sk42Coordinates.x, sk42Coordinates.y),
      copyable: true,
    },
    {
      label: 'Общая',
      value: `WGS: ${getMonospacedString(
        formatWgsCoords(wgsCoordinates[1], wgsCoordinates[0])
      )}\nСК-42: ${getMonospacedString(
        formatXYCoords(sk42Coordinates.x, sk42Coordinates.y)
      )}`,
      copyable: true,
    },
  ];

  const mediaGalleryActions = [
    {
      title: 'Удалить',
      onClick: (_: number, currentMediaIndex: number) =>
        handleDeleteMediaFile(currentMediaIndex),
    },
  ];

  useEffect(() => {
    const getSk42Coordinates = async () => {
      const sk42Coordinates = await convertWsgToSk42(wgsCoordinates);

      if (sk42Coordinates) {
        setSk42Coordinates(sk42Coordinates);
      }
    };

    getSk42Coordinates();
  }, [wgsCoordinates]);

  return (
    <>
      <ObjectPreviewPopup
        longitude={wgsCoordinates[0]}
        latitude={wgsCoordinates[1]}
        offset={30}
        onClose={onClose}
      >
        <ObjectPreviewHeader name={name} type={type} />
        {!!media.length && (
          <ObjectPreviewMedia
            media={media}
            onMediaClick={() => setMediaGalleryOpen(true)}
          />
        )}
        <ObjectPreviewTable rows={descriptionRows} />
        <ObjectPreviewTitle>Расположение</ObjectPreviewTitle>
        <ObjectPreviewTable rows={locationRows} />
      </ObjectPreviewPopup>
      {isMediaGalleryOpen && (
        <MediaGalleryModal
          mediaFiles={mediaFiles}
          actions={mediaGalleryActions}
          containerClassName="!absolute"
          isDraggable
          onSlideDrop={handleMediaFileDrop}
          onClose={handleMediaModalClose}
        />
      )}
    </>
  );
};
