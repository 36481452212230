import { Layer, Source } from 'react-map-gl';
import { googleLabelLayer, googleLabelSource } from 'configs/map/google';
import { LINE_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { showLabelsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getSafeBeforeId } from 'utils';

export const LabelsSource = () => {
  const showLabels = useAppSelector(showLabelsSelector);
  const { mapRef } = useMapRef();

  if (!showLabels) {
    return null;
  }

  return (
    <Source {...googleLabelSource}>
      <Layer
        beforeId={getSafeBeforeId(mapRef.current, LINE_LAYER)}
        {...googleLabelLayer}
      />
    </Source>
  );
};
