import { FC, JSX, SVGProps, useEffect, useRef, useState } from 'react';
import { ReactComponent as Close } from 'images/newIcons/close.svg';
import { ReactComponent as Planning } from 'images/newIcons/planning.svg';

import { NOTHING_WAS_FOUND_MESSAGE } from '../../../constants/routes';
import { TextInput } from '../index';

interface EntityCardProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
  typeCaption?: string;
  handleClose: () => void;
  query: string;
  setQuery: (v: string) => void;
  handleReportClick?: () => void;
  // todo: implement avatar logic
  // avatar?: string;
}

export const FIELD_CLASSNAME_TO_SEARCH = 'field-class-name-to-search';

export const renderAction = (
  title: string,
  Icon: FC<SVGProps<SVGSVGElement>>,
  onClick: () => void
): JSX.Element => (
  <div
    className="flex items-center justify-center gap-1 cursor-pointer"
    onClick={onClick}
  >
    <div className="icon-container">
      <Icon />
    </div>
    <div className="tpg-c2 text-tpg_title">{title}</div>
  </div>
);

const EntityCard: FC<EntityCardProps> = ({
  children,
  title,
  typeCaption,
  handleClose,
  query,
  setQuery,
  handleReportClick,
}) => {
  const fieldContainerRef = useRef<HTMLDivElement>(null);
  const [showNotFound, setShowNotFound] = useState(false);

  useEffect(() => {
    if (!query) setShowNotFound(false);

    const countFieldsInContainer = (node: Node): number => {
      let count = 0;

      if (node.nodeName === 'DIV') {
        const divNode = node as HTMLDivElement;
        if (divNode.classList.contains(FIELD_CLASSNAME_TO_SEARCH)) {
          count++;
        }
        divNode.childNodes.forEach((childNode) => {
          count += countFieldsInContainer(childNode);
        });
      }

      return count;
    };

    if (fieldContainerRef.current) {
      setShowNotFound(countFieldsInContainer(fieldContainerRef.current) === 0);
    }
  }, [query, fieldContainerRef]);

  return (
    <div className="w-full flex flex-col gap-px shrink-0 overflow-hidden">
      <div className="flex flex-col items-start gap-3 px-6 pt-6">
        <div className="flex justify-between items-start self-stretch">
          <span className="h-16 w-16 bg-light shrink-0 rounded-full flex items-center justify-center">
            <div
              className="flex justify-center align-center shrink-0"
              style={{ padding: '5px', width: '40px', height: '40px' }}
            >
              <Planning
                className="shrink-0"
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          </span>
          <Close
            className="icon-container cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="flex flex-col items-start gap-1">
          <div className="tpg-b1 text-tpg_title max-w-[360px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {title || 'Нет названия'}
          </div>
          <div className="tpg-c1 text-tpg_base">
            {typeCaption || 'Нет типа'}
          </div>
        </div>
        {/*todo: uncomment this when reports are ready*/}
        {/*<div className="flex items-center gap-4">*/}
        {/*  {handleReportClick && (*/}
        {/*    <div onClick={handleReportClick}>*/}
        {/*      {renderAction('Сделать отчет', Report)}*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
      <div className="flex flex-col items-start self-stretch pl-2">
        <TextInput
          className="h-10 rounded-[5px]"
          value={query}
          placeholder="Начните поиск"
          searchIcon
          maxLength={99999}
          onChange={setQuery}
          inputClassName={'tpg-input text-tpg_title'}
          theme={'dark'}
        />
      </div>
      <div className="flex px-6 pb-6 items-start self-stretch flex-[1_0_0] overflow-y-auto">
        <div
          ref={fieldContainerRef}
          className="flex flex-col flex-1 items-center gap-6"
        >
          {showNotFound && query ? (
            <div className="w-full text-tpg_base tpg-c1">
              {NOTHING_WAS_FOUND_MESSAGE}
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

export default EntityCard;
