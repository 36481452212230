import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  appRoutes,
  authRoutes,
  mapRoutes,
  monitoringRoutes,
} from 'constants/routes';
import { useAppSelector } from 'hooks';
import MainLayout from 'layouts/MainLayout';
import Login from 'routes/Login';
import Map from 'routes/Map';
import Monitoring from 'routes/Monitoring';
import PersonReportPage from 'routes/PersonReport';
import { authSelector } from 'store/slices/auth/selectors';

import MapGeospoof from 'components/Map/Tabs/Geospoof';
import { MapLayers } from 'components/Map/Tabs/Layers';
import MapTypes from 'components/Map/Tabs/Types';
import NotFound from 'components/NotFound';

import MapImagery from './components/Map/Tabs/Imagery';
import MapLogistic from './components/Map/Tabs/Logistic';
import MapOperational from './components/Map/Tabs/Operational';
import TableEntityCard from './components/ui/EntityCard/TableEntityCard';
import DumpsScreen from './routes/DumpsScreen/DumpsScreen';
import EncryptorScreen from './routes/Encryptor/EncryptorScreen';
import EntitiesScreen from './routes/Entities/EntitiesScreen';
import ReportsTable from './routes/Reports';
import TranslatorScreen from './routes/Translator/TranslatorScreen';

type TRouteProps = {
  path: string;
  component: FC;
  layout: React.ElementType | null;
};

const authPages = Object.values(authRoutes);
const publicPages = Object.values({
  ...appRoutes,
  ...mapRoutes,
  ...monitoringRoutes,
});

const getProperRedirect = (path: string) => {
  const { accessToken } = useAppSelector(authSelector);

  if (!accessToken && publicPages.includes(path)) {
    return <Navigate to={authRoutes.SIGN_IN} replace />;
  }

  if (accessToken && authPages.includes(path)) {
    return <Navigate to={appRoutes.MAP} replace />;
  }

  return null;
};

const AppRoute: FC<TRouteProps> = ({ component, layout, path }) => {
  const redirect = getProperRedirect(path);
  if (redirect) return redirect;

  const Page = component;

  if (layout) {
    const Layout = layout;

    return (
      <Layout>
        <Page />
      </Layout>
    );
  }

  return <Page />;
};

const App: FC = (): JSX.Element => (
  <Routes>
    <Route
      path={authRoutes.SIGN_IN}
      element={
        <AppRoute path={authRoutes.SIGN_IN} component={Login} layout={null} />
      }
    />
    <Route
      path={appRoutes.MONITORING}
      element={
        <AppRoute
          path={appRoutes.MONITORING}
          component={Monitoring}
          layout={MainLayout}
        />
      }
    >
      <Route path={monitoringRoutes.FEED} element={null} />
      <Route path={monitoringRoutes.BOOKMARKS} />
    </Route>
    <Route
      path={appRoutes.MAP}
      element={
        <AppRoute path={appRoutes.MAP} component={Map} layout={MainLayout} />
      }
    >
      <Route path={mapRoutes.SEARCH} element={<MapGeospoof />} />
      <Route path={mapRoutes.LAYERS} element={<MapLayers />} />
      <Route path={mapRoutes.TYPES} element={<MapTypes />} />
      <Route path={mapRoutes.IMAGERY} element={<MapImagery />} />
      <Route path={mapRoutes.OPERATIONAL} element={<MapOperational />} />
      <Route path={mapRoutes.LOGISTIC} element={<MapLogistic />} />
    </Route>
    <Route
      path={appRoutes.REPORTS}
      element={
        <AppRoute
          path={appRoutes.REPORTS}
          component={ReportsTable}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.ENTITIES}
      element={
        <AppRoute
          path={appRoutes.ENTITIES}
          component={EntitiesScreen}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.ENTITIES + '/:entityId'}
      element={
        <AppRoute
          path={appRoutes.ENTITIES + '/:entityId'}
          component={TableEntityCard}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.DUMPS}
      element={
        <AppRoute
          path={appRoutes.DUMPS}
          component={DumpsScreen}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.REPORT + '/:reportId/version/:versionId'}
      element={
        <AppRoute
          path={appRoutes.REPORT + '/:reportId/version/:versionId'}
          component={PersonReportPage}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.ENCRYPTOR}
      element={
        <AppRoute
          path={appRoutes.ENCRYPTOR}
          component={EncryptorScreen}
          layout={MainLayout}
        />
      }
    />
    <Route
      path={appRoutes.TRANSLATOR}
      element={
        <AppRoute
          path={appRoutes.TRANSLATOR}
          component={TranslatorScreen}
          layout={MainLayout}
        />
      }
    />
    <Route path="/" element={<Navigate replace to={appRoutes.MAP} />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default App;
