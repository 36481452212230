import { FC, ReactNode, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getPrivilegesThunk } from 'store/slices/auth/actions';
import { authSelector } from 'store/slices/auth/selectors';

import Header from 'components/Header';

import './style.scss';

type TLayoutProps = {
  children: ReactNode;
};

const MainLayout: FC<TLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(authSelector);

  useEffect(() => {
    if (accessToken) {
      dispatch(getPrivilegesThunk());
    }
  }, [accessToken]);

  return (
    <main className="main-container flex flex-col">
      <Header />
      {children}
    </main>
  );
};

export default MainLayout;
