import { ROOT_HIERARCHY_ID } from 'constants/entities';
import { MapEntity, MapEntityInfo, MapEntityState } from 'types';
import { Counter, Entity, EntityWithRelations } from 'types/entities';

import { mergeMaps } from 'utils';
import { getInitialLayerEntity } from 'utils/entity';

import { EntitiesMap, EntityCountersMap } from './types';

export const getMapEntity = (
  entity: EntityWithRelations,
  id: number,
  mode: 'initial' | 'partial' | 'full',
  layerTemplateId: number,
  objectTemplateId: number
): MapEntity => {
  const info = {
    type:
      (entity.entity.templateID === layerTemplateId && 'layer') ||
      (entity.entity.templateID === objectTemplateId && 'object'),
  } as MapEntityInfo;

  const states = {
    initial: { storage: 'none', active: false },
    partial: {
      storage: entity.entity.id === id ? 'partial' : 'none',
      active: false,
    },
    full: { storage: 'full', active: true },
  } as Record<string, MapEntityState>;

  return {
    ...entity,
    info: info,
    state: states[mode],
  };
};

export const getInitialMapEntity = (
  entity: Entity,
  layerTemplateId: number,
  objectTemplateId: number,
  parentEntityID?: number
): MapEntity =>
  getMapEntity(
    {
      entity: entity,
      childIDs: [],
      parentIDs: parentEntityID ? [parentEntityID] : [],
    },
    ROOT_HIERARCHY_ID,
    'initial',
    layerTemplateId,
    objectTemplateId
  );

export const getEmptyMapLayerEntity = (
  layerTemplateId: number,
  objectTemplateId: number
): MapEntity =>
  getInitialMapEntity(
    getInitialLayerEntity(layerTemplateId),
    layerTemplateId,
    objectTemplateId
  );

export const getEntitiesMap = (
  entities: EntityWithRelations[],
  id: number,
  mode: 'initial' | 'partial' | 'full',
  layerTemplateId: number,
  objectTemplateId: number
): EntitiesMap =>
  entities.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.entity.id]: getMapEntity(
        curr,
        id,
        mode,
        layerTemplateId,
        objectTemplateId
      ),
    }),
    {}
  );

export const getFilteredEntitiesMap = (
  stashedEntitiesMap: EntitiesMap,
  entitiesMap: EntitiesMap,
  newEntitiesMap: EntitiesMap,
  isFiltering: boolean
) => {
  const mergeEntities = (oldEntity: MapEntity, newEntity: MapEntity) => ({
    ...oldEntity,
    state: {
      storage: oldEntity.state.storage,
      active: newEntity.state.active,
    },
  });

  if (isFiltering) {
    const stashedMap = { ...stashedEntitiesMap, ...entitiesMap };

    const newMap = mergeMaps<MapEntity>(
      newEntitiesMap,
      stashedMap,
      false,
      mergeEntities
    );

    return { stashedEntitiesMap: stashedMap, entitiesMap: newMap };
  } else {
    const stashedMap = {};

    const newMap = mergeMaps<MapEntity>(
      stashedEntitiesMap,
      entitiesMap,
      true,
      mergeEntities
    );

    return { stashedEntitiesMap: stashedMap, entitiesMap: newMap };
  }
};

export const getEntityCountersMap = (counters: Counter[]): EntityCountersMap =>
  counters.reduce((acc, curr) => ({ ...acc, [curr.entityID]: curr.count }), {});

export const filterEntitiesMap = (
  entitiesMap: EntitiesMap,
  filterFunc: (mapEntity: MapEntity) => boolean
): EntitiesMap => {
  const passedEntries = Object.entries(entitiesMap).filter(([_, value]) =>
    filterFunc(value)
  );
  return Object.fromEntries<MapEntity>(passedEntries);
};
