import { FC, memo } from 'react';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';

interface ILayersFooterProps {
  selectedEntitiesCount: number;
  onSelectionReset: () => void;
  onAddProject: () => void;
}

export const LayersFooter: FC<ILayersFooterProps> = memo(
  ({ selectedEntitiesCount, onSelectionReset, onAddProject }) => (
    <div className="w-full h-8 py-[6px] px-4 flex flex-row items-center rounded-b-[10px] bg-light cursor-pointer">
      {selectedEntitiesCount === 0 && (
        <div className="flex gap-1">
          <div className={'flex gap-1 items-center'} onClick={onAddProject}>
            <PlusIcon className={'[&>g>path]:fill-tpg_base'} />
            <div className="tpg-c2 text-tpg_base">ПРОЕКТ</div>
          </div>
        </div>
      )}
      {selectedEntitiesCount > 0 && (
        <div className="flex gap-2">
          <div className="tpg-c2">ВЫБРАНО: {selectedEntitiesCount}</div>
          <div
            className="tpg-c2 text-bright_product"
            onClick={onSelectionReset}
          >
            СБРОСИТЬ
          </div>
        </div>
      )}
    </div>
  )
);

LayersFooter.displayName = 'LayersFooter';
