import { EntityNode } from './types';

export const renderNodeName = (node: EntityNode) => {
  /*
   TODO: temporary hidden until access sharing is ready
  if (node.data.isFolder && !node.level) {
    return (
      <span className="tpg-b2 text-tpg_title group-[.selected]:text-[inherit] group-hover:text-[inherit]">
        {node.data.name}
      </span>
    );
  }
  */

  return (
    <span className="text-tpg_base group-[.selected]:text-[inherit] group-hover:text-[inherit]">
      {node.data.name}
    </span>
  );
};
