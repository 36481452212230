import { RootState } from 'store';

export const coordsModeSelector = (state: RootState) =>
  state.map_v2.map.settings.coordsMode;

export const showLabelsSelector = (state: RootState) =>
  state.map_v2.map.settings.showLabels;

export const showAimSelector = (state: RootState) =>
  state.map_v2.map.settings.showAim;

export const typesSelector = (state: RootState) =>
  state.map_v2.map.settings.types;

export const showCustomCursorsSelector = (state: RootState) =>
  state.map_v2.map.settings.showCustomCursors;
