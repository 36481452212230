import { FC, PropsWithChildren } from 'react';
import { Position } from '@turf/turf';
import { useDoubleClick } from 'hooks/useDoubleClick';
import { useDoubleTap } from 'hooks/useDoubleTap';
import { MapboxEvent } from 'mapbox-gl';

import MarkerWithTouch from 'components/ui/MarkerWithTouch';
import { isMobileDevice } from 'utils';

export type CustomMarkerProps = PropsWithChildren<{
  id: string | number | undefined;
  coordinates: Position;
  onClick?: (e: MouseEvent) => void;
  onDbClick?: (e: MouseEvent) => void;
}>;

export const CustomMarker: FC<CustomMarkerProps> = ({
  coordinates,
  onClick = () => null,
  onDbClick = () => null,
  children,
}) => {
  const [long, lat] = coordinates;

  const handleClick = useDoubleClick(onClick, onDbClick);

  const isMobile = isMobileDevice();

  const handleTouch = useDoubleTap(onClick, onDbClick);

  const handleClickOrTouch = (e: MapboxEvent<MouseEvent>) => {
    isMobile ? handleTouch(e.originalEvent) : handleClick(e.originalEvent);
  };

  return (
    <MarkerWithTouch
      longitude={long}
      latitude={lat}
      onClick={(e) => {
        e.originalEvent.stopPropagation();
        handleClickOrTouch(e);
      }}
    >
      {children}
    </MarkerWithTouch>
  );
};
