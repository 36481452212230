import { FC, HTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import { EditableName } from './EditableName';
import { Name } from './Name';
import { TRenderEditingName } from './types';

interface IObjectProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  editable?: boolean;
  editing?: boolean;
  selected?: boolean;
  renderIcon?: ReactNode;
  renderName?: ReactNode;
  renderEditingName?: TRenderEditingName;
  onEdit?: (value: string) => void;
  onEditingChange?: (editing: boolean) => void;
}

export const Object: FC<IObjectProps> = ({
  name,
  editable,
  editing,
  selected,
  renderIcon,
  renderName,
  renderEditingName,
  onEdit,
  onEditingChange,
  ...props
}) => (
  <div
    className={cn(
      'group w-full flex items-center gap-1 overflow-hidden cursor-pointer tpg-c1',
      selected && 'selected'
    )}
    {...props}
  >
    <div className="w-4 h-4 shrink-0" />
    {renderIcon && (
      <div className="w-[20px] h-[20px] [&>svg>path]:fill-tpg_title group-[.selected]:[&>svg>path]:fill-main_product group-hover:[&>svg>path]:!fill-bright_product">
        {renderIcon}
      </div>
    )}
    <EditableName
      value={name}
      editable={editable}
      editing={editing}
      className="overflow-hidden"
      renderEditingName={renderEditingName}
      onEditingChange={onEditingChange}
      onEdit={onEdit}
    >
      <Name
        value={name}
        className="text-tpg_title group-[.selected]:text-main_product group-hover:!text-bright_product"
      >
        {renderName}
      </Name>
    </EditableName>
  </div>
);
