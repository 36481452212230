import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Geometry } from '@turf/turf';
import { convertCoordinates } from 'api/converter';
import cn from 'classnames';
import { mapEntityParams, predefinedTemplates } from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { FeatureTypes, opacityOptions } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ReactComponent as Copy } from 'images/newIcons/copy.svg';
import { IMediaFile, ISelectOption, XYHCoordinates } from 'interfaces';
import {
  addMediaToEntityThunk,
  deleteEntityThunk,
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { MapEntity, TPosition } from 'types';
import { Entity, PredefinedTemplate } from 'types/entities';

import { MediaGalleryModal } from 'components/MediaGalleryModal';
import {
  Button,
  ColorPiker,
  DatePicker,
  Modal,
  Select,
  TextArea,
  TextInput,
} from 'components/ui';
import Dropzone from 'components/ui/Dropzone';
import EntityCard, {
  FIELD_CLASSNAME_TO_SEARCH,
} from 'components/ui/EntityCard/EntityCard';
import EntityFieldsGroup from 'components/ui/EntityCard/EntityFieldsGroup';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';
import TreeSelect from 'components/ui/TreeSelect';
import {
  copyToClipboard,
  formatDate,
  getCoordinatesCenter,
  getMapObjectParamIdMap,
  notify,
} from 'utils';
import {
  getSelectOptionsFromEnumParam,
  getSelectOptionsFromSearch,
} from 'utils/entity';
import { getDefaultMediaFile } from 'utils/media';

import './style.scss';

interface EntityDetailsProps {
  entity: MapEntity;
  mapObjectTemplate: PredefinedTemplate;
  onClose: () => void;
}

const entityFieldsGroupMixin =
  'gap-0 border border-solid border-dark_product rounded-[10px] overflow-hidden';

export const EntityDetails: FC<EntityDetailsProps> = ({
  entity,
  mapObjectTemplate,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const [mapEntity, setEntity] = useState<MapEntity>(entity);
  const [query, setQuery] = useState('');
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [sk42, setSk42] = useState<XYHCoordinates | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [isSaveAllowed, setSaveAllowed] = useState(false);
  const [media, setMedia] = useState<IMediaFile[]>([]);
  const [layerOptions, setLayerOptions] = useState<ISelectOption[]>([]);
  const [parentEntityID, setParentEntityID] = useState(
    mapEntity?.parentIDs.length
      ? mapEntity.parentIDs[0]
      : localStorage.getItem('default_project')
      ? Number(localStorage.getItem('default_project'))
      : 0
  );
  const mapLayerTemplateId =
    useAppSelector((s) =>
      predefinedTemplateIdSelector(s, predefinedTemplates.MAP_LAYER)
    ) || 0;

  // TODO: move values receiving into util/hook?
  const paramIdMap = useMemo(
    () => getMapObjectParamIdMap(mapObjectTemplate.parameters),
    [mapObjectTemplate.parameters]
  );

  const getParamValue = (paramKey: mapEntityParams) =>
    paramIdMap
      ? mapEntity?.entity.parameters[paramIdMap[paramKey]]?.value
      : null;

  const geometry = useMemo((): Geometry => {
    return getParamValue(mapEntityParams.GEOMETRY);
  }, [mapEntity?.entity.parameters]);

  const [longitude, latitude]: TPosition = useMemo(() => {
    if (!geometry || geometry.coordinates.length == 0) {
      return [0, 0];
    }

    switch (geometry.type) {
      case FeatureTypes.POINT:
        return geometry.coordinates as TPosition;
      case FeatureTypes.LINE:
        return getCoordinatesCenter(geometry.coordinates as TPosition[]);
      case FeatureTypes.POLYGON:
        return getCoordinatesCenter(geometry.coordinates[0] as TPosition[]);
      default:
        return [0, 0];
    }
  }, [geometry, paramIdMap]);

  const isPolygon = useMemo(
    () => geometry && geometry.type == FeatureTypes.POLYGON,
    [geometry]
  );

  const decimalWGS = useMemo(
    () => `${latitude.toFixed(6)}, ${longitude.toFixed(6)}`,
    [longitude, latitude]
  );

  const decimalSk42 = useMemo(() => `X:${sk42?.x}, Y:${sk42?.y}`, [sk42]);

  const dateValue = useMemo(() => {
    const date = getParamValue(mapEntityParams.DATE);
    if (date) {
      return new Date(date);
    }
    return null;
  }, [mapEntity?.entity.parameters]);

  const getSelectOptionsByMapEntityParam = (targetParam: mapEntityParams) => {
    const statusParam = mapObjectTemplate.parameters.find(
      (param) => param.title === targetParam
    );
    return statusParam ? getSelectOptionsFromEnumParam(statusParam) : [];
  };

  const statusSelectOptions = useMemo(
    () => getSelectOptionsByMapEntityParam(mapEntityParams.STATUS),
    []
  );

  const typeSelectOptions = useMemo(
    () => getSelectOptionsByMapEntityParam(mapEntityParams.TYPE),
    []
  );

  const decideIsSaveAllowed = () => {
    const isParentIdPresent = !!parentEntityID;
    const isTitlePresent = !!mapEntity?.entity.title;
    const areRequiredParamsPresent = mapObjectTemplate.parameters
      .filter((param) => param.required)
      .map((param) => param.title as mapEntityParams)
      .filter(
        (paramTitle) => Object.values(mapEntityParams).indexOf(paramTitle) >= 0
      )
      .every((paramTitle) => !!getParamValue(paramTitle));

    return (
      !isLoading &&
      isParentIdPresent &&
      isTitlePresent &&
      areRequiredParamsPresent
    );
  };

  const handleChangeEntityTitle = (title: string) =>
    mapEntity &&
    setEntity({
      ...mapEntity,
      entity: { ...mapEntity.entity, title: title },
    });

  const handleChangeParam = (paramKey: mapEntityParams) => (value: any) => {
    mapEntity &&
      paramIdMap &&
      setEntity({
        ...mapEntity,
        entity: {
          ...mapEntity.entity,
          parameters: {
            ...mapEntity.entity.parameters,
            [paramIdMap[paramKey]]: {
              value: value,
            },
          },
        },
      });
  };

  const isAvailableDelete = !!mapEntity?.entity.id;

  const convertToSk42 = async () => {
    if (!latitude || !longitude) {
      return;
    }

    try {
      const data = await convertCoordinates('wgs', 'sk42', {
        b: latitude,
        l: longitude,
        h: 182,
      });

      if (data.to === 'sk42') {
        setSk42({
          x: Math.round(data?.payload?.x),
          y: Math.round(data?.payload?.y),
          h: data?.payload?.h,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const excludePendingMediaFromEntity = (entity: Entity): Entity => ({
    ...entity,
    parameters: {
      ...entity.parameters,
      [paramIdMap[mapEntityParams.MEDIA]]: {
        ...entity.parameters[paramIdMap[mapEntityParams.MEDIA]],
        value: media.filter((v) => v.file === undefined),
      },
    },
  });

  const uploadPendingMedia = async (entityID: number) => {
    for (let index = 0; index < media.length; index++) {
      const curMedia = media[index];
      curMedia.file &&
        (await dispatch(
          addMediaToEntityThunk({
            entityID,
            paramID: Number(paramIdMap[mapEntityParams.MEDIA]),
            file: curMedia.file,
            mediaArrayIndex: index,
          })
        ).catch(() => notify.error(errorMessages.ENTITY_ADD_MEDIA_ERROR)));
    }
  };

  const handleSaveEntity = async () => {
    if (!mapEntity) {
      return;
    }

    // filtering un-uploaded media to handle it explicitly
    let payload = excludePendingMediaFromEntity(mapEntity.entity);

    const prevParentId = mapEntity.parentIDs.length
      ? mapEntity.parentIDs[0]
      : 0;
    if (!prevParentId && parentEntityID) {
      payload = {
        ...payload,
        parentEntityID: parentEntityID,
      };
    }

    setLoading(true);
    await dispatch(upsertEntityThunk(payload))
      .then(async (r) => {
        if (r.payload && 'id' in r.payload) {
          const targetId = r.payload.id;
          // explicitly setting id for freshly created object to prevent double object creation
          if (!mapEntity.entity.id) {
            setEntity({
              ...mapEntity,
              entity: { ...mapEntity.entity, id: targetId },
            });
          }

          if (prevParentId && parentEntityID) {
            await dispatch(
              relinkEntityThunk({
                entityId: targetId,
                oldParentEntityId: prevParentId,
                newParentEntityId: parentEntityID,
              })
            );
          }
          setEntity({ ...mapEntity, parentIDs: [parentEntityID] });
          await uploadPendingMedia(targetId);
        }
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const handleAddMedia = (newMedia: IMediaFile) => {
    setMedia((prevState) => [...prevState, newMedia]);
  };

  const handleDeleteMedia = (deletedMedia: IMediaFile) =>
    setMedia((prevState) =>
      prevState.filter((v) => v.url !== deletedMedia.url)
    );

  const handleDeleteMediaByIndex = (index: number) =>
    index < media.length && handleDeleteMedia(media[index]);

  const handleCopyCoordinate = (coordinate: string) => {
    copyToClipboard(coordinate);
  };

  const visibleObjectType = typeSelectOptions.find(
    (option) => option.value === getParamValue(mapEntityParams.TYPE)
  );

  const wrapField = (
    label: string,
    element: ReactElement,
    classMixin?: string
  ) => (
    <div
      className={cn(
        FIELD_CLASSNAME_TO_SEARCH,
        'pr-4 py-3 border-b border-solid border-dark_product max-w-[calc(410px-40px-10px)]',
        classMixin
      )}
    >
      <div className="tpg-c2 text-tpg_light">{label}</div>
      <div className="tpg-c1 max-w-[330px] [&_span]:max-w-[300px]">
        {element}
      </div>
    </div>
  );

  const possiblyFilteredField = (
    label: string,
    element: ReactElement,
    classMixin?: string
  ) => {
    if (query && !label.toLowerCase().startsWith(query.toLowerCase())) {
      return null;
    }
    return wrapField(label, element, classMixin);
  };

  const mediaGalleryActions = [
    {
      title: 'Удалить',
      onClick: (_: number, currentFileIndex: number) =>
        handleDeleteMediaByIndex(currentFileIndex),
    },
  ];

  const handleDeleteFeature = () => {
    if (mapEntity?.entity.id) {
      dispatch(deleteEntityThunk(mapEntity.entity.id));
      setDeleteModal(false);
      onClose();
    }
  };

  const handleChangeDate = (date: Date) =>
    handleChangeParam(mapEntityParams.DATE)(formatDate(date));

  useEffect(() => {
    convertToSk42();
  }, [longitude, latitude]);

  useEffect(() => {
    const initialMedia = getParamValue(mapEntityParams.MEDIA) as {
      url: string;
    }[];

    setMedia(initialMedia.map((i) => getDefaultMediaFile(i.url)));
  }, []);

  // this duplicates in MapLayerDetails, we can convert this to custom hook later
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getSelectOptionsFromSearch(
        mapObjectTemplate.template.id,
        setLayerOptions,
        [mapLayerTemplateId]
      ).finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  useEffect(() => {
    setSaveAllowed(decideIsSaveAllowed());
  }, [mapEntity, parentEntityID]);

  return (
    <>
      {isPreviewOpen && (
        <MediaGalleryModal
          onClose={() => setPreviewOpen(false)}
          mediaFiles={media}
          isLoading={isLoading}
          containerClassName="!absolute"
          isDraggable
          onSlideDrop={setMedia}
          actions={mediaGalleryActions}
        />
      )}
      {isDeleteModal && (
        <ConfirmModal
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDeleteFeature}
        />
      )}
      <div className={'feature-details relative z-[5]'}>
        <Modal
          width={'410px'}
          keyboard
          onClose={onClose}
          closeOnOutsideClick={false}
        >
          <EntityCard
            title={mapEntity?.entity.title}
            typeCaption={visibleObjectType?.label}
            handleClose={onClose}
            query={query}
            setQuery={setQuery}
          >
            <EntityFieldsGroup
              title={'Основная информация'}
              childrenWrapperMixin={entityFieldsGroupMixin}
            >
              {possiblyFilteredField(
                'Название',
                <TextInput
                  value={mapEntity?.entity.title || ''}
                  onChange={handleChangeEntityTitle}
                  inputClassName={'tpg-c1 -ml-4 placeholder-tpg_title'}
                  theme={'dark'}
                  size="s"
                  className="pl-0"
                  placeholder="Название"
                />,
                'entity-card-input pl-4'
              )}
              {possiblyFilteredField(
                mapEntityParams.TYPE,
                <TreeSelect
                  withEmpty={false}
                  withSearch
                  value={getParamValue(mapEntityParams.TYPE)}
                  options={typeSelectOptions}
                  onSelect={handleChangeParam(mapEntityParams.TYPE)}
                />,
                'entity-card-select pl-4'
              )}
              {possiblyFilteredField(
                'Проект',
                <Select
                  withSearch
                  searchPlaceholder="Введите название"
                  value={parentEntityID}
                  options={layerOptions}
                  onSelect={setParentEntityID}
                  isExpandable
                  disabled={isLoading}
                />,
                'entity-card-select pl-4'
              )}
              {possiblyFilteredField(
                mapEntityParams.STATUS,
                <TreeSelect
                  withEmpty={false}
                  options={statusSelectOptions}
                  value={getParamValue(mapEntityParams.STATUS)}
                  onSelect={handleChangeParam(mapEntityParams.STATUS)}
                />,
                'entity-card-select pl-4'
              )}
              {possiblyFilteredField(
                mapEntityParams.DATE,
                <DatePicker
                  selected={dateValue}
                  onChange={handleChangeDate}
                  className={'entity-card-datepicker tpg-c1'}
                />,
                'entity-card-datepicker pl-4'
              )}
              {isPolygon &&
                possiblyFilteredField(
                  mapEntityParams.OPACITY,
                  <TreeSelect
                    withEmpty={false}
                    options={opacityOptions}
                    value={getParamValue(mapEntityParams.OPACITY)}
                    onSelect={handleChangeParam(mapEntityParams.OPACITY)}
                  />,
                  'entity-card-select pl-4'
                )}
              {possiblyFilteredField(
                mapEntityParams.DESCRIPTION,
                <TextArea
                  value={getParamValue(mapEntityParams.DESCRIPTION) || ''}
                  rows={5}
                  onChange={handleChangeParam(mapEntityParams.DESCRIPTION)}
                  theme={'dark'}
                  placeholder="Описание..."
                />,
                'entity-card-textarea pl-4'
              )}
              {possiblyFilteredField(
                mapEntityParams.COLOR,
                <ColorPiker
                  initialColor={getParamValue(mapEntityParams.COLOR)}
                  onChange={handleChangeParam(mapEntityParams.COLOR)}
                />,
                'pl-4'
              )}
              {possiblyFilteredField(
                mapEntityParams.MEDIA,
                <Dropzone
                  files={media}
                  onAdd={handleAddMedia}
                  onDelete={handleDeleteMedia}
                  onPreviewClick={() => setPreviewOpen(true)}
                  onMediaReorder={setMedia}
                  acceptedFormat=".mp4,.jpeg,.jpg,.png,.gif,.avi"
                  theme="dark"
                  className="pl-0 pr-0"
                />,
                'entity-card-dropzone pl-4  '
              )}
            </EntityFieldsGroup>
            <EntityFieldsGroup
              title={'Расположение'}
              childrenWrapperMixin={entityFieldsGroupMixin}
            >
              {possiblyFilteredField(
                'Координата WGS',
                <TextInput
                  value={decimalWGS}
                  onChange={() => null}
                  inputClassName={'tpg-c1 text-tpg_title -ml-4'}
                  theme={'dark'}
                  size="s"
                >
                  <div
                    className="icon-container -mr-1"
                    onClick={() => handleCopyCoordinate(decimalWGS)}
                  >
                    <Copy />
                  </div>
                </TextInput>,
                'entity-card-input pl-4'
              )}
              {possiblyFilteredField(
                'Координата СК42',
                <TextInput
                  value={decimalSk42}
                  onChange={() => null}
                  inputClassName={'tpg-c1 text-tpg_title -ml-4'}
                  theme={'dark'}
                  size="s"
                >
                  <div
                    className="icon-container -mr-1"
                    onClick={() => handleCopyCoordinate(decimalSk42)}
                  >
                    <Copy />
                  </div>
                </TextInput>,
                'entity-card-input pl-4'
              )}
            </EntityFieldsGroup>
            <div className="flex self-stretch justify-center px-4">
              <div className="flex justify-around flex-1 tpg-c1">
                <Button
                  title={'Удалить'}
                  disabled={!isAvailableDelete}
                  className={
                    isAvailableDelete ? 'py-2 px-8 bg-error' : 'py-2 px-8'
                  }
                  onClick={
                    isAvailableDelete ? () => setDeleteModal(true) : onClose
                  }
                />
                <Button
                  title="Сохранить"
                  className="py-2 px-8"
                  disabled={!isSaveAllowed}
                  onClick={handleSaveEntity}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </EntityCard>
        </Modal>
      </div>
    </>
  );
};
