import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as CopyIcon } from 'images/newIcons/copy.svg';

import { copyToClipboard } from 'utils';

import styles from './object-preview.module.scss';

export interface IObjectPreviewTableRow {
  label: string;
  value: string;
  copyable?: boolean;
}

export interface IObjectPreviewTableProps {
  rows: IObjectPreviewTableRow[];
  classNames?: { cell?: string };
}

export const ObjectPreviewTable: FC<IObjectPreviewTableProps> = ({
  rows,
  classNames,
}) => (
  <table className="w-[calc(100%+2px)] m-[-1px] border-collapse tpg-b2 text-tpg_base">
    <tbody>
      {rows.map(
        (row, idx) =>
          row.value && (
            <tr
              key={`${row.label}-${idx}`}
              className="border border-solid border-tpg_light"
            >
              <td className="w-[35%] px-3 py-1 align-middle border-r border-solid border-tpg_light">
                <span
                  className={cn(
                    'line-clamp-3 whitespace-pre-line',
                    classNames?.cell
                  )}
                >
                  {row.label}
                </span>
              </td>
              <td className="px-3 py-1 text-tpg_title">
                <div className="flex justify-between items-center">
                  <span
                    className={cn(
                      // TODO: temporary solution, replace it later with 'overflow-hidden'
                      styles['scrollbar_hidden'],
                      'whitespace-pre-line overflow-scroll line-clamp-6',
                      classNames?.cell
                    )}
                  >
                    {row.value}
                  </span>
                  {row.copyable && (
                    <CopyIcon
                      className="w-3 h-[15px] shrink-0 cursor-pointer [&>path]:hover:fill-dark_product"
                      onClick={() => copyToClipboard(row.value)}
                    />
                  )}
                </div>
              </td>
            </tr>
          )
      )}
    </tbody>
  </table>
);
