import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getGeospoof,
  getLinksGroupPictures,
  getLinksUserPictures,
  getRequestIdGroupPictures,
  getRequestIdUserPictures,
  getSearchHistory,
  getSearchInfo,
  getUserLocation,
  postUserLocation,
} from 'api/geospoof';
import {
  getAllGCObjects,
  getAttributes,
  getGCAvailableRanges,
  getImagery,
} from 'api/map';
import { AxiosError, CancelTokenSource } from 'axios';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  IAllGCObjects,
  IDateRangeFilter,
  IGCAvailableRanges,
  IGeospoofEntity,
  IGeospoofResponse,
  IGetUSerLocationRequest,
  IHistoryResponse,
  IImageryFilter,
  IImageryObject,
  ILatLonCoordinates,
  IMapAttributes,
  IRequestEntityPictures,
  IRequestIdEntityPictures,
  IRequestUserLocation,
  IResponseEntityLinksPictures,
  ISearchInfoRequest,
  ISearchInfoResponse,
} from 'interfaces';
import { createLoadSource } from 'services/axios';
import { RootState } from 'store';
import { mapActions } from 'store/slices/map';
import { TCoordinatesObject } from 'types';

import { createThunk } from 'utils';

let searchTimeoutId = null as unknown as NodeJS.Timeout;
let searchLoadSource = null as unknown as CancelTokenSource;
const userLocationSource: { [key: string]: CancelTokenSource | null } = {};
let userLocationRequestTmeout: NodeJS.Timeout | null = null;

export const getAttributesThunk = createThunk<IMapAttributes>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_ATTRIBUTES}`,
  getAttributes,
  errorMessages.GET_ATTRIBUTES_ERROR
);

export const getImageryThunk = createThunk<IImageryObject[], IImageryFilter>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_IMAGERY}`,
  getImagery,
  errorMessages.GET_IMAGERY_ERROR
);

export const getGCRangesThunk = createThunk<IGCAvailableRanges<string>>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_GC_RANGES}`,
  getGCAvailableRanges,
  errorMessages.GET_GC_RANGES_ERROR
);

export const getAllGCObjectsThunk = createThunk<
  IAllGCObjects,
  IDateRangeFilter
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_ALL_GC_OBJECTS}`,
  getAllGCObjects,
  errorMessages.GET_GC_FEATURES_ERROR
);

export const getGeospoofThunk = createAsyncThunk<
  IGeospoofResponse,
  ILatLonCoordinates
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_GEOSPOOF}`,
  async (params, thunkAPI) => {
    try {
      const { data } = await getGeospoof(params);

      thunkAPI.dispatch(getSearchHistoryThunk());

      if (data.searchId) {
        thunkAPI.dispatch(getSearchInfoThunk({ searchId: data.searchId }));
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error,
        message: errorMessages.GET_GEOSPOOF_ERROR,
      });
    }
  }
);

export const getSearchHistoryThunk = createThunk<IHistoryResponse>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_SEARCH_HISTORY}`,
  getSearchHistory,
  errorMessages.GET_SEARCH_HISTORY_ERROR
);

export const getSearchInfoThunk = createAsyncThunk<
  ISearchInfoResponse | null,
  ISearchInfoRequest
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_SEARCH_INFO}`,
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { searchId, count = 1 } = params;
    let nextCount = count;

    clearTimeout(searchTimeoutId);

    if (searchLoadSource) {
      searchLoadSource.cancel();
    }

    if (!state.map.geospoof.isActive) {
      return null;
    }

    try {
      searchLoadSource = createLoadSource();

      const { data } = await getSearchInfo(searchId, searchLoadSource.token);
      let loading = false;

      if (
        state.map.geospoof.objects.progress &&
        data.progress !== state.map.geospoof.objects.progress
      ) {
        nextCount = 1;
      }

      if (data.progress < 100 && nextCount < 30) {
        searchTimeoutId = setTimeout(() => {
          thunkAPI.dispatch(
            getSearchInfoThunk({ searchId, count: nextCount + 1 })
          );
        }, 2000);
        loading = true;
      }

      return { objects: { ...data, searchId }, loading };
    } catch (error) {
      return thunkAPI.rejectWithValue(errorMessages.GET_SEARCH_INFO_ERROR);
    }
  }
);

export const getLoadedUserLocationThunk = createAsyncThunk<
  { location?: TCoordinatesObject; user: IGeospoofEntity },
  IGetUSerLocationRequest
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_LOADED_USER_LOCATION}`,
  async ({ request_id, user_id }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (userLocationRequestTmeout) {
      clearTimeout(userLocationRequestTmeout);
    }

    if (userLocationSource[request_id]) {
      userLocationSource[request_id]?.cancel();
    }

    if (!state.map.geospoof.isActive) {
      return null;
    }

    try {
      userLocationSource[request_id] = createLoadSource();

      const { data } = await getUserLocation(
        { request_id },
        (userLocationSource[request_id] as CancelTokenSource).token
      );

      return data;
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 424) {
        userLocationRequestTmeout = setTimeout(
          () =>
            thunkAPI.dispatch(
              getLoadedUserLocationThunk({ request_id, user_id })
            ),
          5000
        );
      } else {
        return thunkAPI.rejectWithValue({
          message: 'Не удалось найти координаты пользователя',
          id: user_id,
        });
      }
    }
  }
);

export const getPicturesUploadIdThunk = createAsyncThunk<
  null,
  IRequestIdEntityPictures
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_PICTURES_UPLOAD_ID}`,
  async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const field = params.user_id ? 'users' : 'groups';
    const id = params.user_id || (params.group_id as string);
    const searchId = (state as RootState).map.geospoof.objects.searchId;

    try {
      if (searchId) {
        thunkAPI.dispatch(
          mapActions.setPicturesUploadStatus({ field, id, value: true })
        );

        const requestMethod = params.user_id
          ? getRequestIdUserPictures
          : getRequestIdGroupPictures;

        const {
          data: { request_id },
        } = await requestMethod({ ...params, search_id: searchId });

        if (request_id) {
          thunkAPI.dispatch(
            getEntityPicturesThunk({
              request_id,
              searchId,
              field,
              id,
              count: 1,
            })
          );
        }
      }
      return null;
    } catch (error) {
      thunkAPI.dispatch(
        mapActions.setPicturesUploadStatus({ field, id, value: false })
      );

      return thunkAPI.rejectWithValue(
        errorMessages.GET_PICTURES_UPLOAD_ID_ERROR
      );
    }
  }
);

export const getEntityPicturesThunk = createAsyncThunk<
  IResponseEntityLinksPictures | null,
  IRequestEntityPictures
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_ENTITY_PICTURES}`,
  async (params, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { request_id, searchId, field, id, count } = params;

    if (!state.map.geospoof.isActive) {
      return null;
    }

    const method =
      field === 'users' ? getLinksUserPictures : getLinksGroupPictures;

    try {
      const {
        data: { links },
      } = await method({
        request_id,
        search_id: searchId,
      });

      return {
        links,
        field,
        id,
      };
    } catch (error) {
      if (count < 5) {
        setTimeout(() => {
          thunkAPI.dispatch(
            getEntityPicturesThunk({ ...params, count: count + 1 })
          );
        }, 5000);
      } else {
        thunkAPI.dispatch(
          mapActions.setPicturesUploadStatus({ field, id, value: false })
        );

        return thunkAPI.rejectWithValue(
          errorMessages.GET_ENTITY_PICTURES_ERROR
        );
      }
    }

    return null;
  }
);

export const getUserLocationThunk = createAsyncThunk<
  void,
  IRequestUserLocation
>(
  `${reducersNames.MAP}/${asyncActionsNames.GET_USER_LOCATION}`,
  async (params, thunkAPI) => {
    try {
      const { data } = await postUserLocation(params);

      if (data.request_id) {
        thunkAPI.dispatch(
          getLoadedUserLocationThunk({
            request_id: data.request_id,
            user_id: params.user_id,
          })
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error,
        message: errorMessages.GET_GEOSPOOF_ERROR,
        id: params.user_id,
      });
    }
  }
);
