import booleanValid from '@turf/boolean-valid';
import {
  Geometry,
  LineString,
  Point,
  Polygon,
  Position,
  Properties,
} from '@turf/turf';
import * as turf from '@turf/turf';
import type { Feature, FeatureCollection } from 'geojson';
import { ClientAttributeEntity, IProjectile, ISelectOption } from 'interfaces';
import { MapObject, TPosition } from 'types';
import {
  Entity,
  EntityParameter,
  GeometryParameter,
  NumberParameter,
  ParametersIdTitleMap,
  TextParameter,
} from 'types/entities';
import {
  MapEntity,
  MapFeature,
  MapFeatureLine,
  MapFeaturePoint,
  MapFeaturePolygon,
  MapFeatureProperties,
} from 'types/map';

import { formatDate, notify } from 'utils';

import { mapEntityParams } from '../constants/entities';

import { getEntityParameterValue } from './entity';

interface GetFeaturesFromMapEntitiesReturnType {
  features: {
    points: MapFeaturePoint[];
    lines: FeatureCollection<LineString, MapFeatureProperties>;
    polygons: FeatureCollection<Polygon, MapFeatureProperties>;
  };
  labels: {
    points: FeatureCollection<Point>;
    lines: FeatureCollection<Point>;
    polygons: FeatureCollection<Point>;
  };
}

const getFeaturesFromMapEntitiesInitial: GetFeaturesFromMapEntitiesReturnType =
  {
    features: {
      points: [],
      lines: { type: 'FeatureCollection', features: [] },
      polygons: { type: 'FeatureCollection', features: [] },
    },
    labels: {
      points: { type: 'FeatureCollection', features: [] },
      lines: { type: 'FeatureCollection', features: [] },
      polygons: { type: 'FeatureCollection', features: [] },
    },
  };

export const prepareFeatureCollection = (): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [],
});

export const preparePolygonFeature = (
  coordinates: GeoJSON.Position[][],
  properties: GeoJSON.GeoJsonProperties = {}
): Feature => ({
  type: 'Feature',
  geometry: { type: 'Polygon', coordinates: coordinates },
  properties,
});

export const getCoordinatesCenter = (coordinates: TPosition[]): TPosition => {
  const sum = coordinates.reduce(
    (acc, coordinate) => [acc[0] + coordinate[0], acc[1] + coordinate[1]],
    [0, 0]
  );

  return [sum[0] / coordinates.length, sum[1] / coordinates.length];
};

export const roundCoordinates = (
  coordinates: TPosition | TPosition[]
): TPosition | TPosition[] => {
  if (typeof coordinates[0] === 'number') {
    const [lat, lon] = coordinates as TPosition;
    return [parseFloat(lat.toFixed(6)), parseFloat(lon.toFixed(6))];
  } else {
    return (coordinates as TPosition[]).map(
      (position) => roundCoordinates(position) as TPosition
    );
  }
};

export const getZoomPercent = (zoom: number, minZoom = 0, maxZoom = 22) =>
  ((zoom - minZoom) / (maxZoom - minZoom)) * 100;

export const getMetersPerPixel = (latitude: number, zoomLevel: number) => {
  const EARTH_RADIUS = 6378137;
  const TILESIZE = 256;
  const EARTH_CIRCUMFERENCE = 2 * Math.PI * EARTH_RADIUS;

  const scale = Math.pow(2, zoomLevel);
  const worldSize = TILESIZE * scale;
  const latitudeRadians = latitude * (Math.PI / 180);

  return (EARTH_CIRCUMFERENCE * Math.cos(latitudeRadians)) / worldSize;
};

export const getPixelValueByMeters = (
  meters: number,
  latitude: number,
  zoom: number
) => {
  return meters / getMetersPerPixel(latitude, zoom);
};

export const downloadObject = (
  object: string,
  name: string,
  fileOptions?: BlobPropertyBag
) => {
  try {
    const blob = new Blob([object], fileOptions);
    const link = document.createElement('a');

    link.setAttribute('href', URL.createObjectURL(blob));
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch {
    notify.error('Не удалось скопировать файл');
  }
};

export const getAttributeOptionsFromClient = (
  attributes: ClientAttributeEntity[]
): ISelectOption[] =>
  attributes.map(({ name, id, code }) => ({
    label: code === null ? name : code + ' ' + name,
    value: id,
  }));

export const getRadiusFromString = (radius: string) => {
  const regex = /^(\d+(\.\d+)?)-(\d+(\.\d+)?) км$/;
  const match = radius.match(regex);
  if (match) {
    const min_range = parseFloat(match[1]);
    const max_range = parseFloat(match[3]);

    return [min_range, max_range];
  }
  throw new Error('Invalid input format');
};

export const getListOfRangeInMeters = (projectiles: IProjectile[]) =>
  projectiles.map((el) => 1000 * getRadiusFromString(el.range)[1]);

export const getMapObject = (
  entity: MapEntity,
  parametersIdTitleMap: ParametersIdTitleMap
): MapObject => {
  const geometry = getEntityParameterValue<GeometryParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.GEOMETRY
  );

  const color = getEntityParameterValue<TextParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.COLOR
  );

  const opacity = getEntityParameterValue<NumberParameter>(
    entity.entity,
    parametersIdTitleMap,
    mapEntityParams.OPACITY
  );

  return {
    id: String(entity.entity.id),
    name: entity.entity.title,
    type: geometry?.type ?? 'Point',
    coordinates: (geometry?.coordinates as any) ?? [0, 0],
    color: color,
    opacity: opacity,
  };
};

export const createFeature = <
  G extends Geometry = Geometry,
  P extends Properties = Properties
>(
  geometry: G,
  properties: P,
  id?: turf.Id
): MapFeature => turf.feature(geometry as any, properties as any, { id });

export const isFeatureValid = (feature: Feature<any>) => booleanValid(feature);

export const calculateFeatureCenter = (feature: Feature<any>): Position =>
  turf.center(feature).geometry.coordinates;

export const calculateGeometryCenter = (geometry: Geometry): Position => {
  const feature = createFeature(geometry, null);

  return turf.center(feature).geometry.coordinates;
};

export const getFeaturesFromMapEntities = (
  entities: MapEntity[],
  parametersIdTitleMap: ParametersIdTitleMap
) =>
  entities.reduce((acc, curr) => {
    if (curr.info.type !== 'object' || !curr.state.active) {
      return acc;
    }

    const mapObject = getMapObject(curr, parametersIdTitleMap);
    const mapFeature = createFeature(
      { type: mapObject.type, coordinates: mapObject.coordinates },
      {
        title: mapObject.name,
        width: 5,
        lineColor: mapObject.color,
        fillColor: mapObject.color,
        opacity: mapObject.opacity ? mapObject.opacity / 100 : 0.7,
      },
      mapObject.id
    );

    const mapFeatureLabel = createFeature<Point>(
      {
        type: 'Point',
        coordinates: calculateFeatureCenter(mapFeature) as Position,
      },
      { title: mapFeature.properties.title },
      mapObject.id
    );

    if (mapFeature.geometry.type === 'Point') {
      return {
        ...acc,
        features: {
          ...acc.features,
          points: [...acc.features.points, mapFeature as MapFeaturePoint],
        },
        labels: {
          ...acc.labels,
          points: {
            ...acc.labels.points,
            features: [
              ...acc.labels.points.features,
              mapFeatureLabel as Feature<Point>,
            ],
          },
        },
      };
    }

    if (mapFeature.geometry.type === 'LineString') {
      return {
        ...acc,
        features: {
          ...acc.features,
          lines: {
            ...acc.features.lines,
            features: [
              ...acc.features.lines.features,
              mapFeature as MapFeatureLine,
            ],
          },
        },
        labels: {
          ...acc.labels,
          lines: {
            ...acc.labels.lines,
            features: [
              ...acc.labels.lines.features,
              mapFeatureLabel as Feature<Point>,
            ],
          },
        },
      };
    }

    if (mapFeature.geometry.type === 'Polygon') {
      return {
        ...acc,
        features: {
          ...acc.features,
          polygons: {
            ...acc.features.polygons,
            features: [
              ...acc.features.polygons.features,
              mapFeature as MapFeaturePolygon,
            ],
          },
        },
        labels: {
          ...acc.labels,
          polygons: {
            ...acc.labels.polygons,
            features: [
              ...acc.labels.polygons.features,
              mapFeatureLabel as Feature<Point>,
            ],
          },
        },
      };
    }

    return acc;
  }, getFeaturesFromMapEntitiesInitial);

export const getSafeBeforeId = (map: mapboxgl.Map | null, layerId: string) =>
  map?.getLayer(layerId) ? layerId : undefined;

export const getInitialEntity = (
  id = 0,
  mapObjectTemplateID: number,
  paramIdMap: Record<mapEntityParams, string>,
  geometry: GeoJSON.Geometry,
  parentEntityID?: number
): Entity => ({
  id: id,
  parentEntityID,
  templateID: mapObjectTemplateID,
  title: '',
  parameters: {
    [paramIdMap[mapEntityParams.GEOMETRY]]: { value: geometry },
    [paramIdMap[mapEntityParams.OPACITY]]: { value: 25 },
    [paramIdMap[mapEntityParams.DESCRIPTION]]: { value: '' },
    [paramIdMap[mapEntityParams.DATE]]: { value: formatDate(new Date()) },
    [paramIdMap[mapEntityParams.COLOR]]: { value: '#2196f3' },
    [paramIdMap[mapEntityParams.TYPE]]: { value: 'Другое' },
    [paramIdMap[mapEntityParams.STATUS]]: { value: 'Выявлено' },
    [paramIdMap[mapEntityParams.MEDIA]]: { value: [] },
  },
});

// TODO: same as getEntityParameterIdByTitle from utils/entity.tsx
const getParamIdByTitle = (params: EntityParameter[], title: string): string =>
  params.find((el) => el.title === title)?.id.toString() || '0';

// TODO: looks the same as getEntityParametersIdTitleMap from utils/entity?
export const getMapObjectParamIdMap = (
  params: EntityParameter[]
): Record<mapEntityParams, string> => ({
  [mapEntityParams.GEOMETRY]: getParamIdByTitle(
    params,
    mapEntityParams.GEOMETRY
  ),
  [mapEntityParams.DATE]: getParamIdByTitle(params, mapEntityParams.DATE),
  [mapEntityParams.OPACITY]: getParamIdByTitle(params, mapEntityParams.OPACITY),
  [mapEntityParams.DESCRIPTION]: getParamIdByTitle(
    params,
    mapEntityParams.DESCRIPTION
  ),
  [mapEntityParams.COLOR]: getParamIdByTitle(params, mapEntityParams.COLOR),
  [mapEntityParams.TYPE]: getParamIdByTitle(params, mapEntityParams.TYPE),
  [mapEntityParams.STATUS]: getParamIdByTitle(params, mapEntityParams.STATUS),
  [mapEntityParams.MEDIA]: getParamIdByTitle(params, mapEntityParams.MEDIA),
});

export const getProperOpacity = (item: {
  opacity?: number;
  [key: string]: any;
}) => (item.opacity === undefined ? 1 : item.opacity / 100);

export const getQueriedFeaturesBounds = (
  position: { x: number; y: number },
  offset = 3
): [[number, number], [number, number]] => [
  [position.x - offset, position.y - offset],
  [position.x + offset, position.y + offset],
];
