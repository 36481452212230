import { useCallback, useEffect } from 'react';
import { MAPBOX, MAPBOX_MAP_CONTAINER } from 'constants/map';
import { useAppDispatch } from 'hooks';
import MapProvider from 'providers/map/MapProvider';
import { MapRefProvider } from 'providers/map/MapRefProvider';
import { getAttributesThunk, getGCRangesThunk } from 'store/slices/map/actions';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  getMapEntitiesThunk,
  getMapEntityCountersThunk,
  getPredefinedTemplateThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';

import { MapFooter } from 'components/Map/MapFooter';
import { MapHeader } from 'components/Map/MapHeader';
import { MapSidebar } from 'components/Map/MapSidebar';

import { predefinedTemplates } from '../../constants/entities';

import { Map } from './Map';

import './style.scss';

const MapPage = () => {
  const dispatch = useAppDispatch();

  const getMapData = useCallback(async () => {
    const mapObjectTemplateResult = await dispatch(
      getPredefinedTemplateThunk(predefinedTemplates.MAP_OBJECT)
    );

    const objectTemplate =
      mapObjectTemplateResult?.payload &&
      'template' in mapObjectTemplateResult.payload &&
      mapObjectTemplateResult.payload.template;

    dispatch(getPredefinedTemplateThunk(predefinedTemplates.MAP_LAYER));
    dispatch(getAttributesThunk());

    dispatch(getGCRangesThunk());
    dispatch(getMapEntitiesThunk({}));
    dispatch(
      getMapEntityCountersThunk({
        parentEntityID: 0,
        ...(objectTemplate && { templateIDs: [objectTemplate.id] }),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getMapData();

    return () => {
      dispatch(mapEntitiesActions.resetState);
    };
  }, [getMapData]);

  return (
    <>
      <MapHeader />
      <div id={MAPBOX} className="map-container relative overflow-hidden">
        <MapRefProvider>
          <MapProvider>
            <>
              <MapSidebar />
              <div
                id={MAPBOX_MAP_CONTAINER}
                className="map-container__wrapper flex flex-col"
              >
                <Map />
                <MapFooter />
              </div>
            </>
          </MapProvider>
        </MapRefProvider>
      </div>
    </>
  );
};

export default MapPage;
