import { useNavigate } from 'react-router-dom';
import { convertCoordinates } from 'api/converter';
import { DEFAULT_CONVERTER_HEIGHT } from 'constants/converter';
import { mapRoutes } from 'constants/routes';
import { Position } from 'geojson';
import { useAppDispatch, useAppSelector } from 'hooks';
import { mapActions } from 'store';
import { coordsModeSelector } from 'store/slices/map/selectors';

import { copyToClipboard } from 'utils';
import {
  formatWgsCoords,
  formatXYCoords,
  getVisibleCoordMode,
} from 'utils/coordinates';

import { ContextPopup } from '.';

interface MapContextMenuProps {
  longitude: number;
  latitude: number;
  onAddEntity: (position: Position) => void;
  onClose: () => void;
}

export const MapContextMenu = ({
  longitude,
  latitude,
  onAddEntity,
  onClose,
}: MapContextMenuProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const footerCoordsMode = useAppSelector(coordsModeSelector);

  const copyCoordinateButtonTitle = `Скопировать координату в ${getVisibleCoordMode(
    footerCoordsMode
  )}`;

  const navigateToGeospoof = () => navigate(mapRoutes.SEARCH);

  const handleGeospoofClick = () => {
    navigateToGeospoof();
    dispatch(mapActions.setExternalGeospoofRequest([longitude, latitude]));
  };

  const handleGeospoofHistoryClick = () => {
    navigateToGeospoof();
    dispatch(mapActions.setGeospoofTab('history'));
  };

  const getCustomCrsCoords = async (
    targetCrs: 'sk42' | 'usk2000'
  ): Promise<{ x: number; y: number } | undefined> => {
    const data = await convertCoordinates('wgs', targetCrs, {
      b: latitude,
      l: longitude,
      h: DEFAULT_CONVERTER_HEIGHT,
    });
    if (data.to === targetCrs) {
      return { x: data.payload.x, y: data.payload.y };
    }
  };

  const handleCopyCoordinate = async () => {
    if (footerCoordsMode === 'wgs') {
      copyToClipboard(formatWgsCoords(latitude, longitude));
    } else {
      const convertedCoords = await getCustomCrsCoords(footerCoordsMode);
      if (convertedCoords) {
        copyToClipboard(formatXYCoords(convertedCoords.x, convertedCoords.y));
      }
    }
  };

  const options = [
    { title: 'Геоспуф', onClick: handleGeospoofClick },
    { title: copyCoordinateButtonTitle, onClick: handleCopyCoordinate },
    { title: 'История геоспуфа', onClick: handleGeospoofHistoryClick },
    {
      title: 'Создать объект',
      onClick: () => onAddEntity([longitude, latitude]),
    },
  ];

  return (
    <ContextPopup
      options={options}
      longitude={longitude}
      latitude={latitude}
      keyboard
      onClose={onClose}
    />
  );
};
