import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapDraw } from 'configs/map/instances/mapDraw';
import { initialGeospoofState } from 'constants/geospoof';
import {
  initialFeatureFiltersState,
  initialImageryState,
  initialMapAttributesState,
  initialMapTypesState,
} from 'constants/map';
import { reducersNames } from 'constants/reducers';
import dayjs from 'dayjs';
import {
  IDeleteMode,
  IErrorHandler,
  IFeatureGlobalFilters,
  IGeospoofEntity,
  IImageryObject,
  IMapMediaPreview,
  IMapState,
  IPicturesUploadStatus,
  IUserLocationError,
} from 'interfaces';
import {
  IFeatureFilterUnion,
  TDateRange,
  TGeospoofObjectKeysUnion,
  TGeospoofTabsUnion,
  TGlobalFiltersUnion,
  TPosition,
  ValueOf,
} from 'types';

import { geospoofErrorHandler, getMediaFiles, isVideo, notify } from 'utils';

import {
  getAllGCObjectsThunk,
  getAttributesThunk,
  getEntityPicturesThunk,
  getGCRangesThunk,
  getGeospoofThunk,
  getImageryThunk,
  getLoadedUserLocationThunk,
  getPicturesUploadIdThunk,
  getSearchHistoryThunk,
  getSearchInfoThunk,
  getUserLocationThunk,
} from './actions';

const initialState: IMapState = {
  isModified: false,
  geospoof: initialGeospoofState,
  filters: initialFeatureFiltersState,
  deleteMode: null,
  mapTypes: initialMapTypesState,
  mediaPreviewMode: null,
  measurements: {
    currentMeasureControl: '',
    measureResult: null,
  },
  attributes: initialMapAttributesState,
  artillery: {
    isActive: false,
    range: [],
    position: null,
    name: '',
  },
  gcObjects: {},
  imagery: initialImageryState,
  isAim: false,
  isLabelOnMap: true,
  coordsMode: 'wgs',
  operationalRange: [null, null],
  geocoderResultCoordinates: undefined,
  openedPopup: null,
  currentEntity: null,
};

const mapSlice = createSlice({
  name: reducersNames.MAP,
  initialState,
  reducers: {
    setMeasurementResult(state, action) {
      state.measurements.measureResult = action.payload;
    },
    setArtilleryStatus(state, action) {
      state.artillery.isActive = action.payload;
    },
    setArtilleryRange(state, action) {
      state.artillery.range = action.payload;
    },
    setArtilleryName(state, action) {
      state.artillery.name = action.payload;
    },
    setArtilleryCoordinates(state, action) {
      state.artillery.position = action.payload;
    },
    resetArtilleryState(state) {
      state.artillery.name = '';
      state.artillery.range = [];
      state.artillery.isActive = false;
      state.artillery.position = null;
    },
    setMapTypes(state, action) {
      const currentType = state.mapTypes[action.payload];
      if (currentType.type === 'style') {
        if (currentType.active) return;
        for (const key in state.mapTypes) {
          if (key !== action.payload && state.mapTypes[key].type === 'style') {
            state.mapTypes[key].active = false;
          }
        }
      }

      state.mapTypes[action.payload].active =
        !state.mapTypes[action.payload].active;
    },
    setMapTypesData(state, action) {
      state.mapTypes[action.payload.field].data = action.payload.data;
    },
    setOperationalRange(state, action: PayloadAction<TDateRange>) {
      state.operationalRange = action.payload;
    },
    // Geospoof actions
    setGeospoofStatus(state, action) {
      state.geospoof.isActive = action.payload;
    },
    setGeospoofCoordinates(state, action) {
      state.geospoof.searchPosition = action.payload;
    },
    setExternalGeospoofRequest(state, action: PayloadAction<TPosition | null>) {
      if (action.payload) {
        state.geospoof.searchPosition = action.payload;
        state.geospoof.runExternalRequest = true;
      } else {
        state.geospoof.runExternalRequest = false;
      }
    },
    setObjectCoordinates(state) {
      state.geospoof.objectCoordinates = state.geospoof.searchPosition;
    },
    setCurrentGeospoofObject(
      state,
      action: PayloadAction<{
        field: TGeospoofTabsUnion;
        data: IGeospoofEntity | null;
      }>
    ) {
      state.geospoof.current[action.payload.field] = action.payload.data;
      state.mediaPreviewMode = null;
    },
    setVisibleGeospoofObjects(state, action) {
      state.geospoof.visibleObjects = action.payload;
    },
    addVisibleImageryObjects(state, action: PayloadAction<IImageryObject>) {
      state.imagery.visibleImageryObjects = [
        ...state.imagery.visibleImageryObjects,
        action.payload,
      ];
    },
    removeVisibleImageryObjects(state, action: PayloadAction<IImageryObject>) {
      state.imagery.visibleImageryObjects = [
        ...state.imagery.visibleImageryObjects.filter(
          (value) => value.id !== action.payload.id
        ),
      ];
    },
    updateVisibleImageryObjects(state, action: PayloadAction<IImageryObject>) {
      state.imagery.visibleImageryObjects = [
        ...state.imagery.visibleImageryObjects.filter(
          (value) => value.id !== action.payload.id
        ),
        action.payload,
      ];
    },
    resetCurrentObject(state) {
      state.geospoof.current.groups = null;
      state.geospoof.current.users = null;
      state.geospoof.current.history = null;
    },
    resetGeospoofState(state) {
      state.geospoof = initialGeospoofState;
      state.filters = initialFeatureFiltersState;
    },

    setPicturesUploadStatus(
      state,
      action: PayloadAction<IPicturesUploadStatus>
    ) {
      const { field, id, value } = action.payload;

      state.geospoof.objects[field] = state.geospoof.objects[field].map(
        (entity) => {
          if (entity.id === id) {
            return {
              ...entity,
              isMediaLoading: value,
            };
          }
          return entity;
        }
      );
    },
    setGeospoofTab(state, action: PayloadAction<TGeospoofObjectKeysUnion>) {
      state.geospoof.currentTab = action.payload;
    },
    setLoadingUserLocation(state, action) {
      state.geospoof.loadingUserLocation = {
        ...state.geospoof.loadingUserLocation,
        [action.payload]: true,
      };
    },
    // Filters actions
    toggleFilterCheckbox(state, action: PayloadAction<IFeatureFilterUnion>) {
      const isChecked = !state.filters[action.payload];

      const filters = {
        ...state.filters,
        [action.payload]: isChecked,
      };

      if (action.payload === 'all') {
        for (const key in filters) {
          filters[key as IFeatureFilterUnion] = isChecked;
        }
      } else {
        filters.all = filters.lines && filters.markers && filters.polygons;
      }

      state.filters = filters;
    },
    setGlobalFiltersValue(
      state,
      action: PayloadAction<{
        field: TGlobalFiltersUnion;
        value: string | number | TDateRange | null;
      }>
    ) {
      (state.filters.global[
        action.payload.field
      ] as ValueOf<IFeatureGlobalFilters>) = action.payload.value;
    },
    toggleImageryUploadModal(state, action: PayloadAction<boolean>) {
      state.imagery.isImageryUploadModalOpen = action.payload;
    },
    toggleImageryPreviewState(state, action: PayloadAction<boolean>) {
      state.imagery.showImageryPreview = action.payload;
    },
    setIsAim(state, action: PayloadAction<boolean>) {
      state.isAim = action.payload;
    },
    setIsLabelOnMap(state, action: PayloadAction<boolean>) {
      state.isLabelOnMap = action.payload;
    },
    setGeocoderResult(state, action: PayloadAction<TPosition | undefined>) {
      state.geocoderResultCoordinates = action.payload;
    },
    // DeleteMode actions
    setDeleteMode(state, action: PayloadAction<IDeleteMode | null>) {
      state.deleteMode = action.payload;
    },
    // Media Preview actions
    setMediaPreviewMode(state, action: PayloadAction<IMapMediaPreview | null>) {
      state.mediaPreviewMode = action.payload;
    },
    // Measurements actions
    setCurrentMeasurement(state, action) {
      if (action.payload === state.measurements.currentMeasureControl) {
        state.measurements.currentMeasureControl = '';
      } else {
        state.measurements.currentMeasureControl = action.payload;
      }

      mapDraw.deleteAll();
      state.measurements.measureResult = null;
    },
    setCurrentEntity(state, action) {
      state.currentEntity = action.payload;
    },
    setCoordsMode(state, action) {
      state.coordsMode = action.payload;
    },
    setOpenedPopup(state, action) {
      state.openedPopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Geospoof thunks
      .addCase(getGeospoofThunk.fulfilled, (state, action) => {
        state.geospoof.pending = false;
        state.geospoof.objects = {
          ...state.geospoof.objects,
          ...action.payload,
        };

        state.geospoof.objectCoordinates = state.geospoof.searchPosition;
      })
      .addCase(getGeospoofThunk.pending, (state) => {
        state.geospoof.pending = true;
        state.geospoof.current = {
          users: null,
          groups: null,
          history: null,
        };
        state.geospoof.objectCoordinates = null;
        state.mediaPreviewMode = null;
      })
      .addCase(getGeospoofThunk.rejected, (state, action) => {
        state.geospoof.pending = false;
        state.geospoof = {
          ...initialGeospoofState,
          current: state.geospoof.current,
          objects: state.geospoof.objects,
          visibleObjects: state.geospoof.visibleObjects,
        };
        geospoofErrorHandler(action.payload as IErrorHandler);
      })
      .addCase(getPicturesUploadIdThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      })
      .addCase(getEntityPicturesThunk.pending, (state) => {
        state.mediaPreviewMode = {
          list: [],
          mode: 'geospoof',
          isLoading: true,
        };
      })
      .addCase(getEntityPicturesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const { links, field, id } = action.payload;

          state.geospoof.objects[field] = state.geospoof.objects[field].map(
            (entity) => {
              if (entity.id === id) {
                let mediaArray = links;
                let image = entity.thumbnail;

                if (!image && mediaArray.length) {
                  image = mediaArray.find((link) => !isVideo(link)) || null;
                }

                if (!mediaArray.length && image) {
                  mediaArray = [image];
                }

                return {
                  ...entity,
                  mediaArray,
                  thumbnail: image,
                  isMediaLoading: false,
                };
              }
              return entity;
            }
          );

          const currentObject = state.geospoof.current[field];

          if (currentObject?.id === id && links.length) {
            state.mediaPreviewMode = {
              list: getMediaFiles(links),
              mode: 'geospoof',
            };

            if (!currentObject.thumbnail && links.length) {
              const image = links.find((link) => !isVideo(link));
              state.geospoof.current[field] = {
                ...currentObject,
                thumbnail: image,
              };
            }
          }
        }
      })
      .addCase(getEntityPicturesThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      })
      .addCase(getSearchHistoryThunk.fulfilled, (state, action) => {
        state.geospoof.objects = {
          ...state.geospoof.objects,
          history: action.payload.search_history,
          userLocationHistory: action.payload.user_location_history,
        };

        state.geospoof.isHistoryLoading = false;
      })
      .addCase(getSearchHistoryThunk.pending, (state) => {
        state.geospoof.isHistoryLoading = true;
      })
      .addCase(getSearchHistoryThunk.rejected, (state) => {
        state.geospoof.isHistoryLoading = false;
      })
      .addCase(getSearchInfoThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.geospoof.objects = {
            ...state.geospoof.objects,
            ...action.payload.objects,
          };
          state.geospoof.isSearchInfoLoading = action.payload.loading;
          state.geospoof.objectCoordinates = state.geospoof.searchPosition;
        }
      })
      .addCase(getSearchInfoThunk.pending, (state) => {
        state.geospoof.isSearchInfoLoading = true;
      })
      .addCase(getSearchInfoThunk.rejected, (state, action) => {
        state.geospoof.isSearchInfoLoading = false;

        if (state.geospoof.currentTab === 'history') {
          state.geospoof.objects = {
            ...state.geospoof.objects,
            groups: [],
            users: [],
          };
          delete state.geospoof.objects.searchId;
        }

        notify.error(action.payload as string);
      })
      .addCase(getLoadedUserLocationThunk.fulfilled, (state, action) => {
        const loadedId = action.payload?.user.id;
        const cordinates = action.payload?.location;

        if (cordinates && loadedId) {
          for (const key in state.geospoof.current) {
            const current =
              state.geospoof.current[key as TGeospoofObjectKeysUnion];

            if (current && current?.id === loadedId) {
              state.geospoof.current[key as TGeospoofObjectKeysUnion] = {
                ...current,
                coordinates: [cordinates.lon, cordinates.lat] as TPosition,
              };
            }
          }

          if (
            state.geospoof.objects.users.some((item) => item.id === loadedId)
          ) {
            state.geospoof.objects.users = state.geospoof.objects.users.map(
              (item) => {
                if (item.id === loadedId) {
                  return {
                    ...item,
                    coordinates: [cordinates.lon, cordinates.lat] as TPosition,
                  };
                }
                return item;
              }
            );
          }

          if (
            state.geospoof.objects.userLocationHistory.some(
              (item) => item.requested_user.id === loadedId
            )
          ) {
            state.geospoof.objects.userLocationHistory =
              state.geospoof.objects.userLocationHistory.map((item) => {
                if (item.requested_user.id === loadedId) {
                  return {
                    ...item,
                    coordinates: [cordinates.lon, cordinates.lat] as TPosition,
                  };
                }
                return item;
              });
          }

          state.geospoof.loadingUserLocation[loadedId] = false;
        }
      })
      .addCase(getUserLocationThunk.rejected, (state, action) => {
        const { error, id, message } = action.payload as IUserLocationError &
          Pick<IErrorHandler, 'error'>;

        state.geospoof.loadingUserLocation[id] = false;

        geospoofErrorHandler({ error, message });
      })
      .addCase(getLoadedUserLocationThunk.rejected, (state, action) => {
        const error = action.payload as IUserLocationError;

        state.geospoof.loadingUserLocation[error.id] = false;

        if (
          state.geospoof.objects.userLocationHistory.some(
            (item) => item.requested_user.id === error.id
          )
        ) {
          state.geospoof.objects.userLocationHistory =
            state.geospoof.objects.userLocationHistory.map((item) => {
              if (item.requested_user.id === error.id) {
                return { ...item, request_failed: false };
              }
              return item;
            });
        }

        notify.error(error.message);
      })
      // Attributes thunks
      .addCase(getAttributesThunk.fulfilled, (state, action) => {
        state.attributes = action.payload;
      })
      .addCase(getAttributesThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      })
      .addCase(getGCRangesThunk.fulfilled, (state, action) => {
        state.gcRanges = {
          frontline_min: dayjs(action.payload.frontline_min).toDate(),
          frontline_max: dayjs(action.payload.frontline_max).toDate(),
          placemark_min: dayjs(action.payload.placemark_min).toDate(),
          placemark_max: dayjs(action.payload.placemark_max).toDate(),
        };
      })
      .addCase(getGCRangesThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      })
      .addCase(getAllGCObjectsThunk.fulfilled, (state, action) => {
        state.gcObjects = action.payload;
      })
      .addCase(getAllGCObjectsThunk.rejected, (state, action) => {
        notify.error(action.payload as string);
      })
      .addCase(getImageryThunk.fulfilled, (state, action) => {
        state.imagery.imageryObjects = action.payload;
      });
  },
});

export const { actions: mapActions, reducer: mapReducer } = mapSlice;
