import { FC, PropsWithChildren, useState } from 'react';
import cn from 'classnames';

import { BurgerMenu, BurgerMenuItem } from 'components/BurgerMenu';

import './style.scss';
import styles from './Header.module.scss';

type HeaderProps = PropsWithChildren<{
  burgerMenuItems?: BurgerMenuItem[];
  className?: string;
}>;

export const Header: FC<HeaderProps> = ({
  burgerMenuItems,
  className,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className={styles['bottom-header']}>
      <div className={cn('flex flex-row items-center', className)}>
        <BurgerMenu
          isOpen={isOpen}
          items={burgerMenuItems ?? []}
          toggleMenu={toggleMenu}
          handleCloseModal={handleCloseMenu}
        />
        {children}
      </div>
    </nav>
  );
};
