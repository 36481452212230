import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { geoconfirmedLayers, MAP_LAYERS_NAMES } from 'constants/mapTypes';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks';
import { mapActions } from 'store/slices/map';
import {
  gcRangesSelector,
  mapTypesSelector,
  operationalRangeSelector,
} from 'store/slices/map/selectors';

import TreeSelectWithDatepicker from 'components/ui/TreeSelectWithDatepicker';

import { relevanceTypes } from '../../../../constants/map';
import { IOperationalSettings } from '../../../../interfaces';
import { TDateRange } from '../../../../types';
import SidebarWindowWithTabs from '../../../ui/SidebarWindowWithTabs';
import TypeItem from '../Types/TypeItem';

import styles from './operational.module.scss';

const OPERATIONAL_DATE_FORMAT = 'DD.MM.YYYY';

const MapOperational = () => {
  const dispatch = useAppDispatch();
  const mapTypes = useAppSelector(mapTypesSelector);
  const gcRanges = useAppSelector(gcRangesSelector);

  const operationalRange = useAppSelector(operationalRangeSelector);
  const [startDate, endDate] = operationalRange;

  const currentDate = new Date();
  const storedSettings: IOperationalSettings = JSON.parse(
    localStorage.getItem('operational_settings') ||
      `{"relevance": "3days", "gcPlacemarks": "true", "gcFrontline": "true"}`
  );
  const [tabSettings, setTabSettings] =
    useState<IOperationalSettings>(storedSettings);
  const [relevance, setRelevance] = useState(tabSettings.relevance);
  const [isSelectorDatepickerVisible, setIsSelectorDatepickerVisible] =
    useState(false);
  const datepickerRef = useRef<HTMLDivElement>(null);

  const handleSetMapTypes = useCallback(
    (type: string) => () => {
      dispatch(mapActions.setMapTypes(type));
    },
    [mapTypes]
  );

  useEffect(() => {
    if (
      !mapTypes[MAP_LAYERS_NAMES.gcFrontline].active &&
      storedSettings.gcFrontlineOn
    )
      dispatch(mapActions.setMapTypes(MAP_LAYERS_NAMES.gcFrontline));
    if (
      !mapTypes[MAP_LAYERS_NAMES.gcPlacemarks].active &&
      storedSettings.gcPlacemarksOn
    )
      dispatch(mapActions.setMapTypes(MAP_LAYERS_NAMES.gcPlacemarks));
  }, []);

  const getDateRangeFromRelevance = (): TDateRange => {
    const startDate = new Date(currentDate);
    switch (relevance) {
      case '1day': {
        startDate.setDate(currentDate.getDate() - 1);
        return [startDate, currentDate];
      }
      case '3days': {
        startDate.setDate(currentDate.getDate() - 3);
        return [startDate, currentDate];
      }
      case '1week': {
        startDate.setDate(currentDate.getDate() - 7);
        return [startDate, currentDate];
      }
      case '1month': {
        startDate.setMonth(currentDate.getMonth() - 1);
        return [startDate, currentDate];
      }
      case '3months': {
        startDate.setMonth(currentDate.getMonth() - 3);
        return [startDate, currentDate];
      }
      case '6months': {
        startDate.setMonth(currentDate.getMonth() - 6);
        return [startDate, currentDate];
      }
      case '1year': {
        startDate.setFullYear(currentDate.getFullYear() - 1);
        return [startDate, currentDate];
      }
      case 'fromStart': {
        return [new Date(2022, 1, 24), currentDate];
      }
      default: {
        startDate.setDate(currentDate.getDate() - 1);
        return [startDate, currentDate];
      }
    }
  };

  useEffect(() => {
    const currentSettings: IOperationalSettings = {
      relevance: relevance,
      gcFrontlineOn: mapTypes[MAP_LAYERS_NAMES.gcFrontline].active,
      gcPlacemarksOn: mapTypes[MAP_LAYERS_NAMES.gcPlacemarks].active,
    };
    setTabSettings(currentSettings);
    localStorage.setItem(
      'operational_settings',
      JSON.stringify(currentSettings)
    );
  }, [relevance, mapTypes, operationalRange]);

  useEffect(() => {
    handleSetOperationalRange(getDateRangeFromRelevance());
  }, [relevance]);

  const handleSetOperationalRange = useCallback((payload: TDateRange) => {
    const [payloadStartDate, payloadEndDate] = payload;
    dispatch(
      mapActions.setOperationalRange([payloadStartDate, payloadEndDate])
    );
  }, []);

  const handleCalendarClose = () => {
    if (startDate && !endDate) {
      handleSetOperationalRange([startDate, startDate]);
    }
  };

  const relevanceDisplayValue = useMemo(() => {
    if (relevance === 'other') {
      const formattedStartDate = dayjs(startDate).format(
        OPERATIONAL_DATE_FORMAT
      );
      const formattedEndDate = endDate
        ? dayjs(endDate).format(OPERATIONAL_DATE_FORMAT)
        : 'НЕ ВЫБРАНО';
      return `${formattedStartDate}-${formattedEndDate}`;
    }
    return (
      relevanceTypes.find((val) => val.value === relevance)?.label ??
      'Неизвестно'
    );
  }, [relevance, startDate, endDate]);

  const handleSetRelevance = (v: string) => {
    setRelevance(v);
    setIsSelectorDatepickerVisible(v === 'other');
  };

  const operational = (
    <div className="p-4">
      <div className="tpg-b2 pb-3">Оперативная ситуация</div>
      {geoconfirmedLayers.map((item, index) => (
        <TypeItem
          {...item}
          key={index}
          active={mapTypes[item.name].active}
          onSwitch={handleSetMapTypes(item.name)}
        />
      ))}
      <div className="flex flex-row justify-between text-tpg_title">
        <p className="tpg-c1">Актуальность</p>
        <div
          className={cn(
            'tpg-c2 hover:cursor-pointer',
            isSelectorDatepickerVisible
              ? 'text-dark_product'
              : 'hover:text-bright_product'
          )}
          onClick={() =>
            setIsSelectorDatepickerVisible(!isSelectorDatepickerVisible)
          }
        >
          <div>{relevanceDisplayValue}</div>
        </div>
      </div>
      <div ref={datepickerRef}>
        {isSelectorDatepickerVisible && (
          <TreeSelectWithDatepicker
            datepickerValue={'other'}
            selectorValue={relevance}
            selectorOptions={relevanceTypes}
            onSelect={handleSetRelevance}
            minDate={gcRanges?.frontline_min}
            maxDate={gcRanges?.frontline_max}
            startDate={startDate}
            endDate={endDate}
            setDate={handleSetOperationalRange}
            onCalendarClose={handleCalendarClose}
            visibleBackPropagate={setIsSelectorDatepickerVisible}
            menuPlacement="bottom"
            optionsClassName={styles['operational-selector']}
            datepickerClassName={styles['operational-datepicker']}
          />
        )}
      </div>
    </div>
  );

  return (
    <SidebarWindowWithTabs
      tabs={[operational]}
      tabOptions={['Оперативная ситуация']}
      hideOptions={true}
    />
  );
};

export default MapOperational;
