import { useMemo, useState } from 'react';
import {
  MAP_ENTITY_FILTERABLE_PARAMS,
  MAP_ENTITY_PARAM_VALUES,
  predefinedTemplates,
} from 'constants/entities';
import { MAP_ENTITIES_LOADER } from 'constants/loaders';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FilterCriteria } from 'interfaces/entity';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import { getMapFilteredEntitiesThunk } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import {
  entitiesMapSelector,
  entityCountersMapSelector,
  hierarchySelector,
  objectEntityParametersSelector,
  predefinedTemplateSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { EntitiesMap } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/types';
import { loaderSelector } from 'store/slices/service/loadersSlice/selectors';
import { useDebouncedCallback } from 'use-debounce';

import { LayerDetails, LayerDetailsProps } from 'components/Map/LayerDetails';
import { Loader, TextInput } from 'components/ui';
import { FilterDropdown } from 'components/ui/Filters';
import { ActiveFilterCriterias } from 'components/ui/Filters/ActiveFilterCriterias';
import { ActiveFilterCriteria } from 'components/ui/Filters/types';
import {
  getFilterableParameters,
  getFilterCriteria,
} from 'components/ui/Filters/utils';
import { getEntityParametersIdTitleMap } from 'utils/entity';

import { EntitiesTree } from './EntitiesTree';
import { LayersFooter } from './LayersFooter';
import { ActiveFilterCriteriasMap } from './types';
import {
  getEntitiesTree,
  getFilterCriteriaParam,
  getFilterCriteriaParams,
  getSelectedObjectEntitiesCount,
} from './utils';

export const MapLayers = () => {
  const entitiesMap = useAppSelector(entitiesMapSelector);
  const entityCountersMap = useAppSelector(entityCountersMapSelector);
  const hierarchy = useAppSelector(hierarchySelector);
  const objectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const objectParameters = useAppSelector(objectEntityParametersSelector);
  const isLoading = useAppSelector((state) =>
    loaderSelector(state, MAP_ENTITIES_LOADER)
  );
  const [layerDetailsProps, setLayerDetailsProps] =
    useState<LayerDetailsProps>();

  const [activeFilterCriteriasMap, setActiveFilterCriteriasMap] =
    useState<ActiveFilterCriteriasMap>({});

  const [search, setSearch] = useState('');

  const dispatch = useAppDispatch();

  const objectParametersIdTitleMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        objectTemplate?.parameters ?? [],
        MAP_ENTITY_PARAM_VALUES,
        'reverse'
      ),
    [objectTemplate]
  );

  const entityFilterableParametersMap = useMemo(
    () =>
      getEntityParametersIdTitleMap(
        objectParameters,
        MAP_ENTITY_FILTERABLE_PARAMS
      ),
    [objectParameters]
  );

  const entityFilterableParameters = useMemo(
    () =>
      getFilterableParameters(objectParameters, entityFilterableParametersMap),
    [objectParameters]
  );

  const filterCriterias = useMemo(
    () =>
      entityFilterableParameters.map((parameter) =>
        getFilterCriteria(parameter)
      ),
    [entityFilterableParameters]
  );

  const activeFilterCriteriasList = useMemo(
    () => getFilterCriteriaParams(activeFilterCriteriasMap),
    [activeFilterCriteriasMap]
  );

  const entitiesTreeData = useMemo(
    () => getEntitiesTree(entitiesMap, entityCountersMap, hierarchy),
    [entitiesMap, entityCountersMap, hierarchy]
  );

  const selectedEntitiesCount = useMemo(
    () => getSelectedObjectEntitiesCount(entitiesMap),
    [entitiesMap]
  );

  const handleFilter = (value: string, filterCriterias: FilterCriteria[]) => {
    // TODO: temporary as filtered results come without hierarhy
    const filterCriteriasParam = !!filterCriterias.length && {
      filterCriteria: filterCriterias,
    };

    dispatch(
      getMapFilteredEntitiesThunk({
        search: value,
        ...filterCriteriasParam,
      })
    );
  };

  const handleSearch = useDebouncedCallback(
    (value: string, filterCriterias: FilterCriteria[]) =>
      handleFilter(value, filterCriterias),
    700
  );

  const handleSearchChange = (value: string) => {
    setSearch(value);
    handleSearch(value, activeFilterCriteriasList);
  };

  const handleFilterCriteriaApply = (criteria: ActiveFilterCriteria) => {
    setActiveFilterCriteriasMap((state) => ({
      ...state,
      [criteria.id]: criteria,
    }));

    handleFilter(search, [
      ...activeFilterCriteriasList,
      getFilterCriteriaParam(criteria),
    ]);
  };

  const handleFilterCriteriaRemove = (criteria: ActiveFilterCriteria) => {
    const { [criteria.id]: deselected, ...restCriterias } =
      activeFilterCriteriasMap;

    setActiveFilterCriteriasMap(restCriterias);
    handleFilter(search, getFilterCriteriaParams(restCriterias));
  };

  const handleEntitiesSelectionReset = () => {
    const deselectedEntitiesMap: EntitiesMap = {};

    for (const id in entitiesMap) {
      const entity = entitiesMap[id];

      deselectedEntitiesMap[id] = {
        ...entity,
        state: { ...entity.state, active: false },
      };
    }

    dispatch(mapEntitiesActions.setEntitiesMap(deselectedEntitiesMap));
  };

  const handleAddProject = () =>
    setLayerDetailsProps({
      onClose: () => setLayerDetailsProps(undefined),
    });

  return (
    <>
      <div className="relative flex flex-col items-start w-full h-full pt-4 rounded-[inherit]">
        <TextInput
          value={search}
          placeholder="Начните поиск"
          searchIcon
          className="w-full px-4"
          onChange={handleSearchChange}
        >
          <FilterDropdown
            criterias={filterCriterias}
            onCriteriaApply={handleFilterCriteriaApply}
          />
        </TextInput>
        <ActiveFilterCriterias
          activeCriterias={Object.values(activeFilterCriteriasMap)}
          onCriteriaRemove={handleFilterCriteriaRemove}
        />
        {isLoading && (
          <Loader className="absolute h-full top-0 z-10 bg-dark rounded-[inherit]" />
        )}
        {/* TODO: Temporary title */}
        <span className="px-9 py-1 tpg-b1">База знаний</span>
        <EntitiesTree
          data={entitiesTreeData}
          objectTemplate={objectTemplate}
          entitiesMap={entitiesMap}
          entityCountersMap={entityCountersMap}
          objectParametersMap={objectParametersIdTitleMap}
          search={search}
          setLayerDetailsProps={setLayerDetailsProps}
        />
        <LayersFooter
          selectedEntitiesCount={selectedEntitiesCount}
          onSelectionReset={handleEntitiesSelectionReset}
          onAddProject={handleAddProject}
        />
      </div>
      {layerDetailsProps && <LayerDetails {...layerDetailsProps} />}
    </>
  );
};
