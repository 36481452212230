import { FC, useEffect, useState } from 'react';
import { useMapRef } from 'hooks/map';
import { MapEntity, MapFeaturePoint } from 'types';
import { useDebouncedCallback } from 'use-debounce';

import { ZoomBasedPoint } from './ZoomBasedPoint';

interface PointsProps {
  points: MapFeaturePoint[];
  activeEntity?: MapEntity;
  onClick?: (point: MapFeaturePoint) => void;
  onDbClick?: (point: MapFeaturePoint) => void;
}

export const Points: FC<PointsProps> = ({
  points,
  activeEntity,
  onClick,
  onDbClick,
}) => {
  const { mapRef } = useMapRef();
  const [zoom, setZoom] = useState(mapRef.current?.getZoom() ?? 0);

  const handleZoomChange = useDebouncedCallback(() => {
    const zoom = mapRef.current?.getZoom() ?? 0;

    setZoom(zoom);
  }, 300);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    mapRef.current?.on('zoom', handleZoomChange);

    return () => {
      mapRef.current?.off('zoom', handleZoomChange);
    };
  }, [handleZoomChange]);

  return (
    <>
      {points.map((point) => (
        <ZoomBasedPoint
          key={point.id}
          id={point.id}
          coordinates={point.geometry.coordinates}
          zoom={zoom}
          color={point.properties.fillColor}
          isActive={String(point.id) === String(activeEntity?.entity?.id)}
          onClick={() => onClick?.(point)}
          onDbClick={() => onDbClick?.(point)}
        />
      ))}
    </>
  );
};
