import {
  MAP_ENTITY_HIERARCHY_NEST_LEVEL,
  predefinedTemplates,
} from 'constants/entities';

import {
  ExportEntityRequest,
  GetEntityCountersRequest,
  IEntityAddMediaRequest,
  IEntityReportRequest,
  IEntitySearchRequest,
  LinkEntityRequest,
} from '../interfaces/entity';
import axios from '../services/axios';
import { Entity, EntityListResponse } from '../types/entities';
import { EntityParameter } from '../types/entities';

import { handleAxiosResponse } from './apiUtils';

const GO_API_PREFIX = '/go';

export const getPredefinedTemplate = (templateTitle: predefinedTemplates) =>
  axios.get(`${GO_API_PREFIX}/entity-template/predefined/${templateTitle}`);

export const searchEntities = (params: IEntitySearchRequest) =>
  handleAxiosResponse<EntityListResponse>(
    axios.post(`${GO_API_PREFIX}/entity/search`, params)
  );

// TODO: temporary copy of searchEntities
export const searchMapEntities = (params: IEntitySearchRequest) =>
  axios.post(`${GO_API_PREFIX}/entity/search`, {
    parentEntityIDs: [0],
    maxNestedEntityLevel: MAP_ENTITY_HIERARCHY_NEST_LEVEL,
    ...params,
  });

export const getEntitiesCounters = (params: GetEntityCountersRequest) =>
  axios.post(`${GO_API_PREFIX}/entity/search/count-V2`, params);

export const deleteEntityNoHandle = (id: number, cascade = true) =>
  axios.delete(`${GO_API_PREFIX}/entity/${id}`, { params: { cascade } });

// todo remove after switch to new redux
export const deleteEntity = (id: number) =>
  handleAxiosResponse<number>(deleteEntityNoHandle(id));

export const upsertEntityNoHandle = (data: Entity) =>
  axios.post(`${GO_API_PREFIX}/entity`, { ...data, createdBy: {} });

// todo remove after switch to new redux
export const upsertEntity = (params: Entity) =>
  handleAxiosResponse<Entity>(upsertEntityNoHandle(params));

export const getAllEntityTemplateParameters = (id: number) =>
  handleAxiosResponse<EntityParameter[]>(
    axios.get(`${GO_API_PREFIX}/entity-template/${id}/parameters`)
  );

export const buildReport = (params: IEntityReportRequest) =>
  axios.post(`${GO_API_PREFIX}/buildReport`, params);

export const addMediaToEntity = (payload: IEntityAddMediaRequest) =>
  axios.patch(
    `${GO_API_PREFIX}/entity/${payload.entityID}/media/${payload.paramID}`,
    payload.file,
    {
      headers: { 'Content-Type': 'application/octet-stream' },
      params: {
        fileName: payload.file.name,
        mediaArrayIndex: payload.mediaArrayIndex,
      },
    }
  );

export const addEntityLink = (params: LinkEntityRequest) =>
  axios.post(`${GO_API_PREFIX}/entity-link`, params);

export const removeEntityLink = (data: LinkEntityRequest) =>
  axios.delete(`${GO_API_PREFIX}/entity-link`, { data });

export const exportEntity = (data: ExportEntityRequest) =>
  axios.post(`${GO_API_PREFIX}/entity/export`, data, { responseType: 'blob' });
