import { RootState } from 'store';

export const entitiesMapSelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.entitiesMap;

export const temporaryEntitiesSelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.temporaryEntities;

export const objectEntityParametersSelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.objectEntityParameters;

export const hierarchySelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.hierarchy;

export const entityCountersMapSelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.entityCountersMap;

export const predefinedTemplatesSelector = (state: RootState) =>
  state.map_v2.tabs.layers.mapEntities.predefinedTemplates;

export const predefinedTemplateSelector = (
  state: RootState,
  template: string
) => state.map_v2.tabs.layers.mapEntities.predefinedTemplates[template];

export const predefinedTemplateIdSelector = (state: RootState, id: string) =>
  state.map_v2.tabs.layers.mapEntities.predefinedTemplates[id]?.template.id;
