import { Geometry } from 'geojson';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

import { ContextPopup } from '.';
interface DrawnFeatureContextMenuProps {
  longitude: number;
  latitude: number;
  feature: MapboxGeoJSONFeature;
  onClose: () => void;
  onCreate: (geometry: Geometry) => void;
}

export const DrawnFeatureContextMenu = ({
  longitude,
  latitude,
  feature,
  onClose,
  onCreate,
}: DrawnFeatureContextMenuProps) => {
  const options = [
    { title: 'Создать объект', onClick: () => onCreate(feature.geometry) },
  ];

  return (
    <ContextPopup
      options={options}
      longitude={longitude}
      latitude={latitude}
      keyboard
      onClose={onClose}
    />
  );
};
