import { FC } from 'react';
import { Position } from '@turf/turf';

import {
  CustomMarker,
  CustomMarkerProps,
} from 'components/Map/FeatureTypes/CustomMarker';
import { getPixelValueByMeters, getZoomPercent } from 'utils';
import { convertHexToRGBA } from 'utils/helpers/color';

interface ZoomBasedPointProps extends CustomMarkerProps {
  zoom: number;
  color?: string;
  isActive?: boolean;
}

interface PointProps {
  zoom: number;
  coordinates: Position;
  color?: string;
  isActive?: boolean;
}

const PinPoint: FC<Pick<PointProps, 'color' | 'isActive'>> = ({
  color,
  isActive,
}) => {
  const outlineColor = isActive ? '#053C92' : '#E3E6F1';

  return (
    <svg
      width="34"
      height="42"
      viewBox="0 0 34 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color }}
    >
      <path
        d="M19.9243 39.4901C20.1389 39.271 20.3551 39.0506 20.5726 38.8289C23.448 35.8975 26.55 32.7351 28.9868 29.2958C31.611 25.5919 33.5 21.5187 33.5 17C33.5 11.5484 31.6654 7.40719 28.6524 4.63222C25.6461 1.86344 21.516 0.5 17 0.5C12.4839 0.5 8.35382 1.86344 5.34752 4.63222C2.33449 7.40719 0.499959 11.5484 0.499959 17C0.499959 21.5188 2.38888 25.5919 5.01311 29.2958C7.44994 32.7352 10.552 35.8976 13.4274 38.8289C13.6449 39.0506 13.8611 39.2711 14.0756 39.4902C14.6538 40.0808 15.1882 40.567 15.6541 40.9092C15.8871 41.0803 16.1131 41.223 16.3272 41.3246C16.5356 41.4236 16.7665 41.5 17 41.5C17.2334 41.5 17.4643 41.4236 17.6728 41.3246C17.8868 41.223 18.1128 41.0803 18.3458 40.9092C18.8117 40.567 19.3461 40.0808 19.9243 39.4901Z"
        fill="currentColor"
        stroke={outlineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 24.5C21.1421 24.5 24.5 21.1421 24.5 17C24.5 12.8579 21.1421 9.5 17 9.5C12.8579 9.5 9.5 12.8579 9.5 17C9.5 21.1421 12.8579 24.5 17 24.5ZM17 26C21.9706 26 26 21.9706 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9706 12.0294 26 17 26Z"
        fill="#E3E6F1"
      />
    </svg>
  );
};

const IconPoint: FC<Pick<PointProps, 'color' | 'isActive'>> = ({
  color,
  isActive,
}) => {
  const outlineColor = isActive ? '#053C92' : '#E3E6F1';

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color }}
    >
      <circle
        cx="17"
        cy="17"
        r="16.5"
        fill="currentColor"
        stroke={outlineColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 24.5C21.1421 24.5 24.5 21.1421 24.5 17C24.5 12.8579 21.1421 9.5 17 9.5C12.8579 9.5 9.5 12.8579 9.5 17C9.5 21.1421 12.8579 24.5 17 24.5ZM17 26C21.9706 26 26 21.9706 26 17C26 12.0294 21.9706 8 17 8C12.0294 8 8 12.0294 8 17C8 21.9706 12.0294 26 17 26Z"
        fill="#E3E6F1"
      />
    </svg>
  );
};

const TransparentPoint: FC<Omit<PointProps, 'zoomPercent'>> = ({
  zoom,
  coordinates,
  color,
  isActive,
}) => {
  const size = getPixelValueByMeters(15, coordinates[0], zoom);
  const outlineColor = isActive ? '#053C92' : color;
  const backgroundColor = convertHexToRGBA(color ?? '#000000', 40);

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: size,
        borderColor: outlineColor,
        backgroundColor: backgroundColor,
      }}
      className="border-[3px] border-solid"
    />
  );
};

// TODO: temporary static point. Replace with map symbol after actual icon come from BE
const Point: FC<PointProps> = ({ zoom, coordinates, color, isActive }) => {
  const zoomPercent = getZoomPercent(zoom);

  if (zoomPercent < 50) {
    return <IconPoint color={color} isActive={isActive} />;
  }

  if (zoomPercent < 80) {
    return <PinPoint color={color} isActive={isActive} />;
  }

  return (
    <TransparentPoint
      zoom={zoom}
      coordinates={coordinates}
      color={color}
      isActive={isActive}
    />
  );
};

export const ZoomBasedPoint: FC<ZoomBasedPointProps> = ({
  coordinates,
  zoom,
  color,
  isActive,
  ...props
}) => (
  <CustomMarker coordinates={coordinates} {...props}>
    <Point
      zoom={zoom}
      coordinates={coordinates}
      color={color}
      isActive={isActive}
    />
  </CustomMarker>
);
