import { getPrivileges, login } from 'api/auth';
import { errorMessages } from 'constants/errors';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import { ILoginResponse, ILoginValues, IUserPrivileges } from 'interfaces';

import { createThunk } from 'utils';

export const loginThunk = createThunk<ILoginResponse, ILoginValues>(
  `${reducersNames.AUTH}/${asyncActionsNames.LOGIN}`,
  login
);

export const getPrivilegesThunk = createThunk<IUserPrivileges, undefined>(
  `${reducersNames.AUTH}/${asyncActionsNames.GET_PRIVILEGES}`,
  getPrivileges,
  errorMessages.GET_PRIVILEGES_ERROR
);
