import { useAppDispatch, useAppSelector } from 'hooks';
import { settingsActions } from 'store/slices/mapV2/mapReducer/settingsSlice';
import {
  showAimSelector,
  showCustomCursorsSelector,
  showLabelsSelector,
} from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { Header } from 'components/Header/Header';

export const MapHeader = () => {
  const showAim = useAppSelector(showAimSelector);
  const showLabels = useAppSelector(showLabelsSelector);
  const showCustomCursors = useAppSelector(showCustomCursorsSelector);

  const dispatch = useAppDispatch();

  const burgerMenuItems = [
    {
      title: `${showAim ? 'Выключить' : 'Включить'} прицел`,
      onClick: () => dispatch(settingsActions.setShowAim(!showAim)),
    },
    {
      title: showLabels
        ? 'Скрыть названия с карты'
        : 'Отобразить названия на карте',
      onClick: () => dispatch(settingsActions.setShowLabels(!showLabels)),
    },
    {
      title: `${
        showCustomCursors ? 'Отключить' : 'Включить'
      } кастомные курсоры`,
      onClick: () =>
        dispatch(settingsActions.setShowCursors(!showCustomCursors)),
    },
  ];

  return <Header burgerMenuItems={burgerMenuItems} />;
};
