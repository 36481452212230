import { useState } from 'react';
import { IMediaFile, ISelectOption } from 'interfaces';
import { EntityWithRelations } from 'types/entities';

import { isVideo } from './monitoring';

export * from './coordinates';
export * from './geospoof';
export * from './helpers/date';
export * from './helpers/string';
export * from './map';
export * from './monitoring';
export * as notify from './notifications';
export * from './store';
export * from './tokenStore';

export const getFilteredOptions = (
  list: ISelectOption[],
  value: string
): ISelectOption[] => {
  if (value) {
    return list.filter((item: ISelectOption) => {
      if (item.children) {
        item.children = getFilteredOptions(item.children, value);

        if (item.children.length) {
          return true;
        }
      }

      return item.label.toLowerCase().includes(value.toLowerCase());
    });
  }

  return list;
};

export const getMediaFiles = (
  files: Array<string | Omit<IMediaFile, 'type'> | IMediaFile>
): IMediaFile[] => {
  if (typeof files === 'string') {
    return [
      {
        url: files,
        type: isVideo(files) ? 'video' : 'image',
      },
    ];
  }

  if (files.length) {
    return files.map((item) => ({
      ...(typeof item === 'object' ? item : {}),
      url: typeof item === 'object' ? item.url : item,
      type: isVideo(
        (item as IMediaFile).file
          ? ((item as IMediaFile)?.file as File).name
          : typeof item === 'object'
          ? item.url
          : item
      )
        ? 'video'
        : 'image',
    }));
  }

  return [];
};

export const getMonospacedString = (value: string) => `\`${value}\``;

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
};

export const mergeMaps = <T,>(
  oldMap: Record<string, T>,
  newMap: Record<string, T>,
  mergeUnmatchedItems = true,
  mergeMatchedItems: (oldItem: T, newItem: T) => T = (oldItem: T, newItem: T) =>
    newItem
) => {
  const mergedMap = { ...oldMap };

  for (const id in newMap) {
    const oldItem = oldMap[id];
    const newItem = newMap[id];

    if (oldItem) {
      mergedMap[id] = mergeMatchedItems(oldItem, newItem);
    }

    if (!newItem && mergeUnmatchedItems) {
      mergedMap[id] = newItem;
    }
  }

  return mergedMap;
};

export const replaceOrAppendArrayValue = <T,>(
  arr: T[],
  value: T,
  compFn: (item: T, value: T) => boolean
) => {
  const res = [...arr];
  const index = arr.findIndex((item) => compFn(item, value));

  if (index === -1) {
    res.push(value);
  } else {
    res.splice(index, 1, value);
  }

  return res;
};

export const convertEntityWithRelationsToSelectOptions = (
  entities: EntityWithRelations[],
  mapObjectTemplateId: number,
  excludeIDs?: number[]
): ISelectOption[] => {
  const entityMap = new Map<number, EntityWithRelations>();
  entities.forEach((entityWithRelations) => {
    entityMap.set(entityWithRelations.entity.id, entityWithRelations);
  });

  const isIdAllowed = (id: number) =>
    excludeIDs ? !excludeIDs.includes(id) : true;

  function buildOption(
    entityWithRelations: EntityWithRelations
  ): ISelectOption {
    const { entity, childIDs } = entityWithRelations;

    const option: ISelectOption = {
      label: entity.title,
      value: entity.id,
      children: null,
    };

    if (childIDs.length > 0) {
      const childrenOptions = childIDs
        .filter(isIdAllowed)
        .map((childID) => {
          const childEntityWithRelations = entityMap.get(childID);
          if (
            childEntityWithRelations &&
            childEntityWithRelations.entity.templateID !== mapObjectTemplateId
          ) {
            return buildOption(childEntityWithRelations);
          }
          return null;
        })
        .flatMap((child) => (child ? [child] : []));

      if (childrenOptions.length > 0) {
        option.children = childrenOptions;
      }
    }

    return option;
  }

  const rootOptions: ISelectOption[] = [];
  entities
    .filter((entityWithRelations) => isIdAllowed(entityWithRelations.entity.id))
    .forEach((entityWithRelations) => {
      if (entityWithRelations.parentIDs.length === 0) {
        rootOptions.push(buildOption(entityWithRelations));
      }
    });

  return rootOptions;
};

export function safe<Type>(func: () => Type): Type | undefined {
  try {
    return func();
  } catch {
    return;
  }
}

export const isMobileDevice = () => {
  return navigator.maxTouchPoints > 0;
};
