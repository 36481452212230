import { FC, memo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { labelConfig } from 'configs/map/common';
import { LABEL_SOURCE } from 'constants/map';
import { MapObject } from 'interfaces';

interface LabelProps extends MapObject {
  config?: LayerProps;
}

export const Label: FC<LabelProps> = memo(
  ({ id = LABEL_SOURCE, data, config = labelConfig }) => (
    <Source id={id} type="geojson" data={data}>
      <Layer id={`${id}-layer`} {...config} />
    </Source>
  )
);

Label.displayName = 'Label';
