import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addEntityLink,
  addMediaToEntity,
  deleteEntityNoHandle,
  getEntitiesCounters,
  getPredefinedTemplate,
  removeEntityLink,
  searchMapEntities,
  upsertEntityNoHandle,
} from 'api/entities';
import { predefinedTemplates, ROOT_HIERARCHY_ID } from 'constants/entities';
import { errorMessages } from 'constants/errors';
import { MAP_ENTITIES_LOADER } from 'constants/loaders';
import { asyncActionsNames, reducersNames } from 'constants/reducers';
import {
  GetEntityCountersRequest,
  IEntityAddMediaRequest,
  IEntitySearchRequest,
} from 'interfaces/entity';
import { MAP_V2_REDUCER_NAMES } from 'store/slices/mapV2/constants';
import { loadersActions } from 'store/slices/service/loadersSlice';
import { AsyncThunkConfig } from 'types';
import {
  Entity,
  EntityListResponse,
  GetEntityCountersResponse,
  PredefinedTemplate,
} from 'types/entities';

import { buildThunk } from 'utils';

import { TABS_REDUCER_NAMES } from '../../constants';
import { LAYERS_REDUCER_NAMES } from '../constants';

import { GetMapEntityChildrenThunkData, RelinkEntityThunkData } from './types';

export const getPredefinedTemplateThunk = buildThunk<
  PredefinedTemplate,
  predefinedTemplates
>(
  `${reducersNames.MAP_V2}`,
  errorMessages.GET_PREDEFINED_TEMPLATES_ERROR,
  getPredefinedTemplate
);

export const getMapEntitiesThunk = buildThunk<
  EntityListResponse,
  IEntitySearchRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITIES}`,
  errorMessages.GET_MAP_ENTITIES_ERROR,
  searchMapEntities,
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(
      loadersActions.addLoader({
        id: MAP_ENTITIES_LOADER,
        loading: true,
        error: null,
      })
    ),
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(loadersActions.removeLoader(MAP_ENTITIES_LOADER))
);

export const getMapFilteredEntitiesThunk = buildThunk<
  EntityListResponse,
  IEntitySearchRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_FILTERED_ENTITIES}`,
  errorMessages.GET_MAP_FILTERED_ENTITIES_ERROR,
  searchMapEntities,
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(
      loadersActions.addLoader({
        id: MAP_ENTITIES_LOADER,
        loading: true,
        error: null,
      })
    ),
  (request, thunkAPI) =>
    thunkAPI.dispatch?.(loadersActions.removeLoader(MAP_ENTITIES_LOADER))
);

export const getMapEntityChildrenThunk = buildThunk<
  EntityListResponse,
  GetMapEntityChildrenThunkData
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITY_CHILDREN}`,
  errorMessages.GET_MAP_ENTITY_CHILDREN_ERROR,
  searchMapEntities
);

export const upsertEntityThunk = buildThunk<Entity, Entity>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.UPSERT_ENTITY}`,
  errorMessages.ENTITY_CREATION_ERROR,
  // todo switch to function with proper naming
  upsertEntityNoHandle
);

export const deleteEntityThunk = buildThunk<number, number>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.DELETE_ENTITY}`,
  errorMessages.ENTITY_DELETE_ERROR,
  // todo switch to function with proper naming
  deleteEntityNoHandle
);

export const addMediaToEntityThunk = buildThunk<Entity, IEntityAddMediaRequest>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.ADD_MEDIA_TO_ENTITY}`,
  errorMessages.ENTITY_ADD_MEDIA_ERROR,
  addMediaToEntity
);

export const getMapEntityCountersThunk = buildThunk<
  GetEntityCountersResponse,
  GetEntityCountersRequest
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.GET_MAP_ENTITY_COUNTERS}`,
  errorMessages.GET_MAP_ENTITY_COUNTERS_ERROR,
  getEntitiesCounters
);

export const relinkEntityThunk = createAsyncThunk<
  unknown,
  RelinkEntityThunkData,
  AsyncThunkConfig
>(
  `${reducersNames.MAP_V2}/${MAP_V2_REDUCER_NAMES.TABS}/${TABS_REDUCER_NAMES.LAYERS}/${LAYERS_REDUCER_NAMES.MAP_ENTITIES}/${asyncActionsNames.RELINK_ENTITY}`,
  async (params, thunkAPI) => {
    try {
      const isMovingFromRoot = params.oldParentEntityId === ROOT_HIERARCHY_ID;
      const isMovingToRoot = params.newParentEntityId === ROOT_HIERARCHY_ID;

      if (params.oldParentEntityId === params.newParentEntityId) {
        return;
      }

      if (isMovingFromRoot) {
        await addEntityLink({
          childID: params.entityId,
          parentID: params.newParentEntityId,
        });

        return;
      }

      if (isMovingToRoot) {
        await removeEntityLink({
          childID: params.entityId,
          parentID: params.oldParentEntityId,
        });

        return;
      }

      await Promise.all([
        addEntityLink({
          childID: params.entityId,
          parentID: params.newParentEntityId,
        }),
        removeEntityLink({
          childID: params.entityId,
          parentID: params.oldParentEntityId,
        }),
      ]);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error,
        message: errorMessages.RELINK_ENTITY_ERROR,
      });
    }
  }
);
