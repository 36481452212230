import { RootState } from 'store';

export const geospoofSelector = (state: RootState) => state.map.geospoof;

export const mediaPreviewModeSelector = (state: RootState) =>
  state.map.mediaPreviewMode;

export const mapTypesSelector = (state: RootState) => state.map.mapTypes;

export const mapAttributesSelector = (state: RootState) => state.map.attributes;

export const gcRangesSelector = (state: RootState) => state.map.gcRanges;

export const gcObjectsSelector = (state: RootState) => state.map.gcObjects;

export const imagerySelector = (state: RootState) => state.map.imagery;

export const coordsModeSelector = (state: RootState) => state.map.coordsMode;

export const operationalRangeSelector = (state: RootState) =>
  state.map.operationalRange;

export const geocoderResultSelector = (state: RootState) =>
  state.map.geocoderResultCoordinates;

export const openedPopupSelector = (state: RootState) => state.map.openedPopup;

export const currentEntitySelector = (state: RootState) =>
  state.map.currentEntity; // TODO move to new redux
