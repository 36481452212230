import { FC, ReactNode } from 'react';
import { ReactComponent as PlanningIcon } from 'images/newIcons/planning.svg';

interface IObjectPreviewHeader {
  icon?: ReactNode;
  type?: string;
  name: string;
}

export const ObjectPreviewHeader: FC<IObjectPreviewHeader> = ({
  icon = <PlanningIcon />,
  type,
  name,
}) => (
  <div
    className={`flex m-[-1px] rounded-t-[inherit] 
border border-solid border-tpg_light justify-between`}
  >
    <div
      className={`flex justify-center 
items-center w-[114px] h-[80px] bg-light rounded-l-[inherit]`}
    >
      {icon}
    </div>
    <div className="px-4 py-[14.5px] w-[calc(100%-114px)]">
      <p className="tpg-b1 text-tpg_base">{type}</p>
      <p className="tpg-h4 line-clamp-1">{name}</p>
    </div>
  </div>
);
