import { ReactComponent as Train } from 'images/newIcons/train.svg';

import { ReactComponent as Fire } from '../images/newIcons/fire.svg';
import { ReactComponent as Logistic } from '../images/newIcons/logisticPassive.svg';
import { ReactComponent as Mountain } from '../images/newIcons/mountain.svg';
import streetMap from '../images/raster/googleStreets.png';
import imagery from '../images/raster/imagery.png';
import minimalism from '../images/raster/minimalism.png';
import satellite from '../images/raster/satellite.png';

export enum MAP_LAYERS_NAMES {
  googleSatellite = 'googleSatellite',
  mapboxMinimalism = 'mapboxMinimalism',
  streetMap = 'streetMap',
  mapboxSatellite = 'mapboxSatellite',
  infrastructure = 'infrastructure',
  elevation = 'elevation',
  fire = 'fire',
  demMap = 'demMap',
  railroads = 'railroads',
  gcFrontline = 'gcFrontline',
  gcPlacemarks = 'gcPlacemarks',
  googleSatelliteUpper = 'googleSatelliteUpper',
  mapboxSatelliteUpper = 'mapboxSatelliteUpper',
}

export const mapBackingLayers = [
  {
    label: 'Google Satellite',
    description: 'Спутниковый слой Google',
    name: MAP_LAYERS_NAMES.googleSatellite,
    preview: satellite,
  },
  {
    label: 'Карта улиц',
    description: 'Векторный слой MapBox',
    name: MAP_LAYERS_NAMES.streetMap,
    preview: streetMap,
  },
  {
    label: 'Спутниковые снимки',
    description: 'Спутниковые снимки MapBox',
    name: MAP_LAYERS_NAMES.mapboxSatellite,
    preview: imagery,
  },
  {
    label: 'Минимализм',
    description: 'Минимальная чёрно-белая карта',
    name: MAP_LAYERS_NAMES.mapboxMinimalism,
    preview: minimalism,
  },
];

export const mapUpperLayers = {
  height: [
    {
      label: 'Карта высот',
      description: 'Уровень моря, высота деревьев',
      name: MAP_LAYERS_NAMES.elevation,
      icon: <Mountain />,
    },
    {
      label: 'Карта высот v2.0',
      description: 'Self-hosted данные по высотам',
      name: MAP_LAYERS_NAMES.demMap,
      icon: <Mountain />,
    },
  ],
  fire: {
    label: 'Слой пожаров',
    description: 'Пожары и термальные точки',
    name: MAP_LAYERS_NAMES.fire,
    icon: <Fire />,
  },
  imagery: [
    {
      label: 'Google Satellite',
      description: 'Спутниковый слой Google',
      name: MAP_LAYERS_NAMES.googleSatelliteUpper,
      preview: satellite,
    },
    {
      label: 'Спутниковые снимки',
      description: 'Спутниковые снимки MapBox',
      name: MAP_LAYERS_NAMES.mapboxSatelliteUpper,
      preview: imagery,
    },
  ],
};

export const geoconfirmedLayers = [
  {
    label: 'Линия боевого соприкосновения',
    name: MAP_LAYERS_NAMES.gcFrontline,
  },
  {
    label: 'Геолоцированные события',
    name: MAP_LAYERS_NAMES.gcPlacemarks,
  },
];

export const logisticLayers = [
  {
    title: 'Слой инфраструктуры',
    description: 'Заводы, производства, энергия',
    icon: <Logistic />,
    name: 'infrastructure',
  },
  {
    title: 'Карта ЖД',
    description: 'Выгрузка ЖД с OSM',
    icon: <Train />,
    name: 'railroads',
  },
];
