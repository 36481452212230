import { FC, memo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { pensLineLayerConfig } from 'configs/map/common';
import { LINE_LAYER } from 'constants/map';
import { MapObject } from 'interfaces';

// TODO: make dynamic id
export const Line: FC<MapObject> = memo(({ data }) => (
  <Source id="line-source" type="geojson" data={data}>
    <Layer {...pensLineLayerConfig} id={LINE_LAYER} />
  </Source>
));

Line.displayName = 'Line';
