import { FC, useCallback, useEffect, useState } from 'react';
import {
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
} from 'react-map-gl';
import MapBox from 'react-map-gl';
import cn from 'classnames';
import { mapDraw } from 'configs/map/instances/mapDraw';
import { predefinedTemplates } from 'constants/entities';
import {
  DRAW_ACTIVE_COLD_LAYER_ID,
  DRAW_ACTIVE_HOT_LAYER_ID,
  DRAW_INACTIVE_COLD_LAYER_ID,
  DRAW_INACTIVE_HOT_LAYER_ID,
  FeatureTypes,
  IMAGERY_PREVIEW_LAYER_ID,
  LAYERS_POLYGON_BOUNDARY,
  LAYERS_POLYGON_FILL,
  LINE_LAYER,
  MAP_BOX_TOKEN,
} from 'constants/map';
import { MAP_DRAW_MODES, MAP_MEASURE_MODES } from 'constants/mapControl';
import { ENTITY_DETAILS_MODAL, ENTITY_PREVIEW_MODAL } from 'constants/modals';
import { AstraCursors, AstraCursorTitle } from 'constants/routes';
import { Geometry, Position } from 'geojson';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMap, useMapRef } from 'hooks/map';
import { useDoubleTap } from 'hooks/useDoubleTap';
import { mapActions } from 'store';
import { authSelector } from 'store/slices/auth/selectors';
import { imagerySelector } from 'store/slices/map/selectors';
import { showCustomCursorsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';
import { artilleryActions } from 'store/slices/mapV2/mapReducer/toolsReducer/artillerySlice';
import { drawActions } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice';
import { drawModeSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';
import { mapEntitiesActions } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice';
import {
  entitiesMapSelector,
  predefinedTemplateSelector,
  temporaryEntitiesSelector,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { getInitialMapEntity } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/utils';
import { modalsActions } from 'store/slices/service/modalsSlice';
import { modalSelector } from 'store/slices/service/modalsSlice/selectors';
import { MapEntity } from 'types';
import { useDebouncedCallback } from 'use-debounce';

import { DrawnFeatureContextMenu } from 'components/Map/ContextPopup/DrawnFeatureContextMenu';
import { MapContextMenu } from 'components/Map/ContextPopup/MapContextMenu';
import Controls from 'components/Map/Controls/Controls';
import { EntityDetails } from 'components/Map/EntityDetails';
import { EntityPreview } from 'components/Map/EntityPreview';
import FeatureCollection from 'components/Map/FeatureCollection';
import { GeoconfirmedObjectsSource } from 'components/Map/GeoconfirmedObjectsSource';
import { Legend } from 'components/Map/Legend';
import { AimSource } from 'components/Map/Sources/AimSource';
import { ArtillerySource } from 'components/Map/Sources/ArtillerySource';
import { DemSource } from 'components/Map/Sources/DemSource';
import { ElevationSource } from 'components/Map/Sources/ElevationSource';
import { FireSource } from 'components/Map/Sources/FireSource';
import { GeocoderSource } from 'components/Map/Sources/GeocoderSource';
import { GoogleSource } from 'components/Map/Sources/GoogleSource';
import { InfrastructureSource } from 'components/Map/Sources/InfrastructureSource';
import { LabelsSource } from 'components/Map/Sources/LabelsSource';
import { LayersSource } from 'components/Map/Sources/LayersSource';
import { MapboxSource } from 'components/Map/Sources/MapboxSource';
import { MeasureSource } from 'components/Map/Sources/MeasureSource';
import { RailroadsSource } from 'components/Map/Sources/RailroadsSource';
import ImageryUploadModal from 'components/Map/Tabs/Imagery/ImageryUploadModal';
import {
  getInitialEntity,
  getMapObjectParamIdMap,
  getQueriedFeaturesBounds,
  isMobileDevice,
  safe,
  useForceUpdate,
} from 'utils';
import { transformTileRequest } from 'utils/tileserver';

interface ContextMenu {
  type: 'map' | 'drawn_feature';
  longitude: number;
  latitude: number;
  props: Record<string, unknown>;
}

export const Map: FC = () => {
  const { tileToken } = useAppSelector(authSelector);
  const { viewport, defaultConfig } = useMap();
  const { mapRef } = useMapRef();
  const drawMode = useAppSelector(drawModeSelector);
  const entitiesMap = useAppSelector(entitiesMapSelector);
  const { imageryObjects } = useAppSelector(imagerySelector);
  const showCustomCursors = useAppSelector(showCustomCursorsSelector);
  const { isImageryUploadModalOpen } = useAppSelector(imagerySelector);
  const mapObjectTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_OBJECT)
  );
  const mapLayerTemplate = useAppSelector((state) =>
    predefinedTemplateSelector(state, predefinedTemplates.MAP_LAYER)
  );
  const temporaryEntities = useAppSelector(temporaryEntitiesSelector);
  const entityPreviewModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_PREVIEW_MODAL)
  );
  const entityDetailsModal = useAppSelector((state) =>
    modalSelector(state, ENTITY_DETAILS_MODAL)
  );
  const [contextMenu, setContextMenu] = useState<ContextMenu | null>(null);

  const isMobile = isMobileDevice();

  const mode = mapRef.current && mapDraw.getMode();

  const forceUpdate = useForceUpdate();

  const [isGrab, setIsGrab] = useState(false);
  const [isCursorOnFeature, setIsCursorOnFeature] = useState(false);
  const [cursor, setCursor] = useState<AstraCursorTitle | undefined>();

  const dispatch = useAppDispatch();

  const isEntityPreviewModalOpen =
    entityPreviewModal?.isOpen &&
    entityPreviewModal?.props &&
    mapObjectTemplate;

  const isEntityDetailsModalOpen =
    entityDetailsModal?.isOpen &&
    entityDetailsModal?.props &&
    mapObjectTemplate;

  const isMapContextMenuOpen = contextMenu?.type === 'map';
  const isDrawnFeatureContextMenuOpen = contextMenu?.type === 'drawn_feature';

  const setViewport = useDebouncedCallback(
    () => localStorage.setItem('viewport', JSON.stringify(viewport)),
    500
  );

  const enableDblClickZoom = () => {
    mapRef.current?.doubleClickZoom.enable();
  };

  const disableDblClickZoom = () => {
    mapRef.current?.doubleClickZoom.disable();
  };

  const queryFeaturesByLayer = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    layers: string[]
  ) =>
    mapRef.current?.queryRenderedFeatures(getQueriedFeaturesBounds(e.point), {
      layers: layers.filter((i) => !!mapRef.current?.getLayer(i)),
    });

  const queryLineAndPolygonLayers = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) =>
    // marker clicks are handled explicitly in Point component
    queryFeaturesByLayer(e, [
      LAYERS_POLYGON_BOUNDARY,
      LAYERS_POLYGON_FILL,
      LINE_LAYER,
    ]);

  const openModalWithEntity = (mapEntity: MapEntity, modalId: string) =>
    dispatch(
      modalsActions.addModal({
        id: modalId,
        isOpen: true,
        props: mapEntity,
      })
    );

  const openModalWithEntityId = (entityID: string, modalId: string) => {
    const entity = entitiesMap[entityID];
    entity && openModalWithEntity(entity, modalId);
  };

  const handleClosePreviewModal = () =>
    dispatch(modalsActions.removeModal(ENTITY_PREVIEW_MODAL));

  const handleCloseDetailsModal = () => {
    dispatch(modalsActions.removeModal(ENTITY_DETAILS_MODAL));
    dispatch(drawActions.setDrawMode(MAP_DRAW_MODES.simple_select));
    dispatch(
      mapEntitiesActions.setTemporaryEntities(
        temporaryEntities.filter((entity) => !entity.state.draft)
      )
    );

    mapDraw.deleteAll();
  };

  const handleAddEntity = useCallback(
    (geometry: Geometry) => {
      if (mapObjectTemplate && mapLayerTemplate) {
        const entity = getInitialEntity(
          new Date().getTime(),
          mapObjectTemplate.template.id,
          getMapObjectParamIdMap(mapObjectTemplate.parameters),
          geometry
        );

        const mapEntity = getInitialMapEntity(
          entity,
          mapLayerTemplate.template.id,
          mapObjectTemplate.template.id
        );

        mapEntity.state.draft = true;
        mapEntity.state.active = true;

        dispatch(
          mapEntitiesActions.setTemporaryEntities([
            ...temporaryEntities,
            mapEntity,
          ])
        );
        safe(() => mapDraw.deleteAll());
        openModalWithEntity(mapEntity, ENTITY_DETAILS_MODAL);
      }
    },
    [mapObjectTemplate, mapLayerTemplate, temporaryEntities]
  );

  const handleAddPoint = (position: Position) =>
    handleAddEntity({
      type: FeatureTypes.POINT,
      coordinates: position,
    });

  const handleImageryClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    const selectedFeatures = queryFeaturesByLayer(e, [
      IMAGERY_PREVIEW_LAYER_ID,
    ]);

    if (
      selectedFeatures?.length &&
      selectedFeatures[0].properties &&
      selectedFeatures[0].properties['imageryId']
    ) {
      const targetId = selectedFeatures[0].properties['imageryId'];
      const targetImagery = imageryObjects.find((i) => i.id === targetId);

      if (targetImagery) {
        dispatch(mapActions.addVisibleImageryObjects(targetImagery));
      }
    }
  };

  const queryFeaturesOpenModal = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    modalId: string
  ) => {
    const queriedFeatures = queryLineAndPolygonLayers(e);
    if (
      queriedFeatures &&
      queriedFeatures?.length > 0 &&
      queriedFeatures[0].id
    ) {
      disableDblClickZoom();
      openModalWithEntityId(String(queriedFeatures[0].id), modalId);
    }
  };

  const handleEntityClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) =>
    !entityDetailsModal?.isOpen &&
    queryFeaturesOpenModal(e, ENTITY_PREVIEW_MODAL);

  const handleMapDoubleClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    enableDblClickZoom();
    handleClosePreviewModal();
    if (isMobile && contextMenu) {
      disableDblClickZoom();
      handleContextMenuClose();
    }
    queryFeaturesOpenModal(e, ENTITY_DETAILS_MODAL);
  };

  const withMapMeasureEnabledClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    measureMode: string
  ) => {
    const { features } = mapDraw.getAll();

    if (measureMode === MAP_MEASURE_MODES.measure_artillery) {
      dispatch(artilleryActions.setPosition([e.lngLat.lng, e.lngLat.lat]));
    }

    if (features.length > 1) {
      mapDraw.delete(features[0].id as string);
    }
  };

  const withMapMeasureDisabledClick = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    handleImageryClick(e);
    handleEntityClick(e);
  };

  const handleMapSingleClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) =>
    drawMode && drawMode !== 'simple_select'
      ? withMapMeasureEnabledClick(e, drawMode)
      : withMapMeasureDisabledClick(e);

  const handleMapClickDebounced = useDebouncedCallback(
    (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
      if (e.originalEvent.detail === 1) {
        handleMapSingleClick(e);
      } else {
        handleMapDoubleClick(e);
      }
    },
    250
  );

  const handleMapClick = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    // preventing zoom on double click

    e.preventDefault();
    isMobile ? handleMapTouch(e) : handleMapClickDebounced(e);
  };

  const handleMapTouch = useDoubleTap(
    handleMapSingleClick,
    handleMapDoubleClick
  );

  const getMapContexMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ): ContextMenu => {
    const { lngLat } = e;

    return {
      type: 'map',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      props: {},
    };
  };

  const getMapDrawnFeatureContextMenu = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    drawMode: string
  ): ContextMenu | null => {
    const drawnFeature = queryFeaturesByLayer(e, [
      DRAW_INACTIVE_COLD_LAYER_ID,
      DRAW_INACTIVE_HOT_LAYER_ID,
      DRAW_ACTIVE_COLD_LAYER_ID,
      DRAW_ACTIVE_HOT_LAYER_ID,
    ])?.[0];

    const { lngLat } = e;

    if (!drawnFeature || drawMode !== MAP_MEASURE_MODES.measure_circle) {
      return null;
    }

    const hasUserGeometry = !!drawnFeature?.properties?.user_geometry;

    const geometry: Geometry = hasUserGeometry
      ? JSON.parse(drawnFeature?.properties?.user_geometry)
      : drawnFeature.geometry;

    const feature: MapboxGeoJSONFeature = {
      ...drawnFeature,
      geometry: geometry,
    };

    return {
      type: 'drawn_feature',
      latitude: lngLat.lat,
      longitude: lngLat.lng,
      props: { feature },
    };
  };

  const handleContextMenuOpen = (
    e: MapLayerMouseEvent | MapLayerTouchEvent
  ) => {
    const mapContexMenu = getMapContexMenu(e);
    const mapDrawFeatureContexMenu = getMapDrawnFeatureContextMenu(e, drawMode);

    setContextMenu(mapDrawFeatureContexMenu ?? mapContexMenu);
  };

  const handleContextMenuClose = () => setContextMenu(null);

  useEffect(() => {
    setViewport();
  }, [viewport]);

  const handleDragStart = () => setIsGrab(true);
  const handleDragEnd = () => setIsGrab(false);

  const getCursorType = () => {
    // TODO: Map doesn't instantly render proper cursor, despite memo returning proper if else branch
    if (showCustomCursors && mapRef.current) {
      if (isGrab) {
        return AstraCursorTitle.GRABBING;
      } else if (mode?.startsWith('measure_') || mode?.startsWith('create_')) {
        return AstraCursorTitle.COPY;
      } else if (isCursorOnFeature) {
        return AstraCursorTitle.POINTER;
      } else {
        return AstraCursorTitle.CROSSHAIR;
      }
    } else {
      mapRef.current && (mapRef.current.getCanvas().style.cursor = '');
      return undefined;
    }
  };

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    const features = queryLineAndPolygonLayers(e);
    features && features.length > 0
      ? setIsCursorOnFeature(true)
      : setIsCursorOnFeature(false);
  };

  useEffect(() => {
    const cursorToSet = getCursorType();
    setCursor(cursorToSet ?? undefined);
  }, [getCursorType, cursor]);

  return MAP_BOX_TOKEN ? (
    <div
      className={cn('w-full h-full relative', {
        'map-container__wrapper__svg-pointer': showCustomCursors,
      })}
      // Sets cursor to random number for cursor enum, this is used to
      // to fire rerender of Map.tsx and apply the cursor properly
      onMouseEnter={() => setCursor(Math.random() % 7)}
    >
      <MapBox
        ref={(ref) => (mapRef.current = ref && ref.getMap())}
        {...defaultConfig}
        {...viewport}
        terrain={{ source: 'mapbox-dem', exaggeration: 2 }}
        onContextMenu={handleContextMenuOpen}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onClick={handleMapClick}
        onDblClick={handleMapClick}
        onTouchEnd={handleMapClick}
        onMouseMove={handleMouseMove}
        transformRequest={(url, type) =>
          transformTileRequest(url, type, tileToken)
        }
        cursor={cursor ? AstraCursors[cursor] : undefined}
      >
        <GoogleSource />
        <MapboxSource />
        <LabelsSource />
        <ElevationSource />
        <DemSource />
        <FireSource />
        <InfrastructureSource />
        <RailroadsSource />
        <AimSource handleMapContextMenuOpen={handleContextMenuOpen} />
        <MeasureSource />
        <GeocoderSource />
        <ArtillerySource />
        <GeoconfirmedObjectsSource />
        <LayersSource />
        <FeatureCollection />
        <Legend />
        <Controls onCreate={handleAddEntity} forceUpdate={forceUpdate} />
        {isMapContextMenuOpen && (
          <MapContextMenu
            longitude={contextMenu.longitude}
            latitude={contextMenu.latitude}
            onClose={handleContextMenuClose}
            onAddEntity={handleAddPoint}
          />
        )}
        {isDrawnFeatureContextMenuOpen && (
          <DrawnFeatureContextMenu
            longitude={contextMenu.longitude}
            latitude={contextMenu.latitude}
            feature={contextMenu.props.feature as MapboxGeoJSONFeature}
            onClose={handleContextMenuClose}
            onCreate={handleAddEntity}
          />
        )}
        {isImageryUploadModalOpen && <ImageryUploadModal />}
        {isEntityPreviewModalOpen && (
          <EntityPreview
            key={entityPreviewModal?.props?.entity?.id}
            entity={entityPreviewModal?.props as MapEntity}
            mapObjectTemplate={mapObjectTemplate}
            onClose={handleClosePreviewModal}
          />
        )}
        {isEntityDetailsModalOpen && (
          <EntityDetails
            key={entityDetailsModal?.props?.entity?.id}
            entity={entityDetailsModal?.props as MapEntity}
            mapObjectTemplate={mapObjectTemplate}
            onClose={handleCloseDetailsModal}
          />
        )}
      </MapBox>
    </div>
  ) : (
    <p>Не удалось загрузить карту. Отсутствует токен</p>
  );
};
