import { LayerProps } from 'react-map-gl';
import { labelConfig, labelLayout, labelPaint } from 'configs/map/common';

export const zoomBasedLayoutConfig = {
  layout: {
    ...labelLayout,
    'text-offset': [
      'interpolate',
      ['linear'],
      ['zoom'],
      1,
      ['literal', [0, 1.4]],
      10,
      ['literal', [0, 1.8]],
    ],
  },
};

export const highlightedPaintConfig = (id?: number) => ({
  paint: {
    ...labelPaint,
    'text-halo-color': [
      'case',
      ['==', ['id'], id ?? null],
      '#053C92',
      '#0A0C17',
    ],
  },
});

export const getPointLabelConfig = (id?: number) =>
  ({
    ...labelConfig,
    ...{
      ...zoomBasedLayoutConfig,
      ...highlightedPaintConfig(id),
    },
  } as LayerProps);

export const getLabelConfig = (id?: number) =>
  ({
    ...labelConfig,
    ...highlightedPaintConfig(id),
  } as LayerProps);
