import { useMemo, useState } from 'react';
import { ReactComponent as CopyIcon } from 'images/newIcons/copy.svg';

import { notify } from 'utils';

import {
  makeTranslation,
  sendBadTranslationFeedback,
} from '../../api/translator';
import { Header } from '../../components/Header/Header';
import { Button, TextArea } from '../../components/ui';
import { errorMessages } from '../../constants/errors';
import { BadTranslationFeedback } from '../../interfaces/translator';
import { copyToClipboard } from '../../utils';

import './TranslatorScreen.scss';

const TranslatorScreen = () => {
  const translatorSymbolLimit = 15000;

  const [translatorInput, setTranslatorInput] = useState('');
  const [translatorOutput, setTranslatorOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [lastTranslation, setLastTranslation] =
    useState<BadTranslationFeedback>();

  const inputLength = useMemo(() => translatorInput.length, [translatorInput]);

  const setConstrainedTranslatorInput = (input: string) =>
    setTranslatorInput(input.slice(0, translatorSymbolLimit));

  const handleTranslate = async () => {
    setIsLoading(true);
    await makeTranslation(translatorInput)
      .then((result) => {
        setTranslatorOutput(result);
        setLastTranslation({
          feedback_type: 'translator',
          text_input: translatorInput,
          text_output: result,
        });
      })
      .catch(() => notify.error(errorMessages.TRANSLATION_ERROR))
      .finally(() => setIsLoading(false));
  };

  const handleBadTranslationFeedback = async () => {
    if (lastTranslation) {
      await sendBadTranslationFeedback(lastTranslation)
        .then(() =>
          notify.success('Спасибо за отметку! Постараемся быть лучше')
        )
        .catch(() => notify.error(errorMessages.ADD_FEATURE_ERROR));
    }
  };

  return (
    <>
      <Header />
      <div className="flex justify-center items-center h-screen">
        <div
          className="astra-single-page-modal gap-8"
          style={{ width: '1134px' }}
        >
          <h1 className="tpg-h4 select-none">Переводчик</h1>
          <div className="flex flex-col items-center gap-6 self-stretch">
            <div className="flex justify-center items-start gap-4 self-stretch">
              <div className="flex flex-col items-center gap-2.5 flex-[1_0_0] self-stretch px-4 py-3 bg-light rounded-[5px]">
                <TextArea
                  value={translatorInput}
                  onChange={setConstrainedTranslatorInput}
                  placeholder={'Введите на украинском'}
                  rows={1}
                  style={{
                    height: '490px',
                    resize: 'none',
                    padding: 0,
                  }}
                  readOnly={isLoading}
                  className={'self-stretch'}
                />
                <div className="flex justify-end self-stretch tpg-c2 text-tpg_base select-none">
                  <div>
                    {inputLength}/{translatorSymbolLimit}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center gap-2.5 flex-[1_0_0] self-stretch px-4 py-3 bg-light rounded-[5px]">
                <TextArea
                  value={translatorOutput}
                  placeholder={'Мы переведем на русский'}
                  rows={5}
                  readOnly
                  style={{
                    height: '490px',
                    resize: 'none',
                    padding: 0,
                  }}
                  className={'self-stretch'}
                />
                <div className="flex justify-between self-stretch tpg-c2 text-tpg_base select-none">
                  <div
                    className="hover:text-tpg_title cursor-pointer"
                    onClick={handleBadTranslationFeedback}
                  >
                    Плохой перевод
                  </div>
                  <div
                    className="group flex items-center gap-1 cursor-pointer"
                    onClick={() => copyToClipboard(translatorOutput)}
                  >
                    <div className="group-hover:text-tpg_title">
                      Скопировать
                    </div>
                    <CopyIcon className="icon-container w-3 h-3" />
                  </div>
                </div>
              </div>
            </div>
            <Button
              title="Перевести"
              className="w-full"
              onClick={handleTranslate}
              isLoading={isLoading}
              disabled={translatorInput.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslatorScreen;
