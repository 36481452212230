import { DrawCustomMode } from '@mapbox/mapbox-gl-draw';
import circle from '@turf/circle';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { MapboxDrawCustomEvents } from 'constants/map';

import { DrawCircleModeState } from './types';

const DrawCircleMode: DrawCustomMode = {
  onSetup: function (opts) {
    // duplicate geometry because coordinates may be broken due to map.queryRenderedFeatures tile processing
    const polygon = this.newFeature({
      type: 'Feature',
      properties: {
        subtype: 'Circle',
        center: [],
        radiusInM: 0,
        geometry: {
          type: 'Polygon',
          coordinates: [],
        },
      },
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
    });

    this.clearSelectedFeatures();
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    this.addFeature(polygon);

    return {
      polygon,
    };
  },
  onClick: function (state: DrawCircleModeState, e) {
    const currentCenter = state.polygon.properties.center;

    if (currentCenter.length === 0) {
      return (state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]);
    } else {
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id],
      });
    }
  },
  onTap: function (state: DrawCircleModeState, e) {
    const currentCenter = state.polygon.properties.center;

    if (currentCenter.length === 0) {
      return (state.polygon.properties.center = [e.lngLat.lng, e.lngLat.lat]);
    } else {
      return this.changeMode('simple_select', {
        featureIds: [state.polygon.id],
      });
    }
  },
  onMouseMove: function (state: DrawCircleModeState, e) {
    const center = state.polygon.properties.center;

    if (center.length > 0) {
      const distanceInM = distance(
        point(center),
        point([e.lngLat.lng, e.lngLat.lat]),
        { units: 'meters' }
      );

      const distanceInKm = distanceInM / 1000;
      const circleFeature = circle(center, distanceInKm);

      state.polygon.incomingCoords(circleFeature.geometry.coordinates);
      state.polygon.properties.radiusInM = distanceInM;
      state.polygon.properties.geometry.coordinates =
        state.polygon.getCoordinates();
    }

    this.map.fire(MapboxDrawCustomEvents.MOUSE_MOVE, e);
  },
  onTouchMove: function (state: DrawCircleModeState, e) {
    const center = state.polygon.properties.center;

    if (center.length > 0) {
      const distanceInM = distance(
        point(center),
        point([e.lngLat.lng, e.lngLat.lat]),
        { units: 'meters' }
      );

      const distanceInKm = distanceInM / 1000;
      const circleFeature = circle(center, distanceInKm);

      state.polygon.incomingCoords(circleFeature.geometry.coordinates);
      state.polygon.properties.radiusInM = distanceInM;
      state.polygon.properties.geometry.coordinates =
        state.polygon.getCoordinates();
    }
  },
  onStop: function (state: DrawCircleModeState) {
    if (state.polygon.isValid()) {
      const polygonGeoJson = state.polygon.toGeoJSON();

      this.map.fire('draw.create', {
        features: [polygonGeoJson],
      });
    } else {
      this.deleteFeature(state.polygon.id, { silent: true });
    }
  },
  toDisplayFeatures: function (state: DrawCircleModeState, geojson, display) {
    display(geojson);
  },
};

export { DrawCircleMode };
