import { FC } from 'react';
import { IMediaFile } from 'interfaces';

import PreviewMediaFiles from 'components/PreviewMediaFiles';
import { getMediaFiles } from 'utils';

interface IObjectPreviewMediaProps {
  media: IMediaFile[];
  mediaOrder?: number[];
  onMediaClick?: () => void;
}

export const ObjectPreviewMedia: FC<IObjectPreviewMediaProps> = ({
  media,
  mediaOrder,
  onMediaClick,
}) => (
  <div className="flex gap-2 p-4 m-[-1px] border-[inherit] border-solid border-tpg_light">
    {media.length ? (
      <PreviewMediaFiles
        visible={3}
        size="m"
        showFullImage={false}
        files={getMediaFiles(media)}
        mediaOrder={mediaOrder}
        onClick={onMediaClick}
      />
    ) : (
      <span className="tpg-b2 text-tpg_base">Нет медиа</span>
    )}
  </div>
);
