import { createSlice } from '@reduxjs/toolkit';
import { ROOT_HIERARCHY_ID } from 'constants/entities';
import { errorMessages, successMessages } from 'constants/errors';

import { notify } from 'utils';
import {
  createHierarchyNode,
  deleteNodeFromHierarchy,
  moveHierarchyNode,
  moveMapEntity,
  processHierarchy,
  updateHierarchyProperty,
  updateMapEntity,
} from 'utils/entity';

import { LAYERS_REDUCER_NAMES } from '../constants';

import {
  addMediaToEntityThunk,
  deleteEntityThunk,
  getMapEntitiesThunk,
  getMapEntityChildrenThunk,
  getMapEntityCountersThunk,
  getMapFilteredEntitiesThunk,
  getPredefinedTemplateThunk,
  relinkEntityThunk,
  upsertEntityThunk,
} from './actions';
import {
  EntitiesState,
  SetEntitiesMapAction,
  SetEntityCountersMapAction,
  SetHierarchyAction,
  SetLayerEntityParametersAction,
  SetObjectEntityParametersAction,
  SetStateAction,
  SetTemporaryEntitiesAction,
} from './types';
import {
  getEntitiesMap,
  getEntityCountersMap,
  getFilteredEntitiesMap,
  getInitialMapEntity,
} from './utils';

const initialState: EntitiesState = {
  stash: { entitiesMap: {} },
  entitiesMap: {},
  temporaryEntities: [],
  entityCountersMap: {},
  hierarchy: { id: ROOT_HIERARCHY_ID, children: [] },
  layerEntityParameters: [],
  objectEntityParameters: [],
  predefinedTemplates: {},
};

const mapEntitiesSlice = createSlice({
  name: LAYERS_REDUCER_NAMES.MAP_ENTITIES,
  initialState: initialState,
  reducers: {
    setState(state, action: SetStateAction) {
      return action.payload;
    },
    setEntitiesMap(state, action: SetEntitiesMapAction) {
      state.entitiesMap = action.payload;
    },
    setTemporaryEntities(state, action: SetTemporaryEntitiesAction) {
      state.temporaryEntities = action.payload;
    },
    setEntityCountersMap(state, action: SetEntityCountersMapAction) {
      state.entityCountersMap = action.payload;
    },
    setHierarchy(state, action: SetHierarchyAction) {
      state.hierarchy = action.payload;
    },
    setFolderEntityParameters(state, action: SetLayerEntityParametersAction) {
      state.layerEntityParameters = action.payload;
    },
    setObjectEntityParameters(state, action: SetObjectEntityParametersAction) {
      state.objectEntityParameters = action.payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getMapEntitiesThunk.fulfilled, (state, action) => {
        const { results, hierarchy, entityRelatedParameters } = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates['mapLayer']?.template.id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates['mapObject']?.template.id || 0;

        state.entitiesMap = getEntitiesMap(
          results,
          ROOT_HIERARCHY_ID,
          'initial',
          mapLayerTemplateId,
          mapObjectTemplateId
        );
        state.hierarchy = { id: ROOT_HIERARCHY_ID, children: hierarchy ?? [] };
        state.layerEntityParameters =
          entityRelatedParameters[mapLayerTemplateId] ?? [];
        state.objectEntityParameters =
          entityRelatedParameters[mapObjectTemplateId] ?? [];
      })
      .addCase(getMapEntitiesThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapFilteredEntitiesThunk.fulfilled, (state, action) => {
        const { filterCriteria, search } = action.meta.arg;
        const { results, hierarchy } = action.payload;
        const isFiltering = !!(filterCriteria?.length || search);
        const mapLayerTemplateId =
          state.predefinedTemplates['mapLayer']?.template.id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates['mapObject']?.template.id || 0;
        const filteredEntitiesMap = getEntitiesMap(
          results,
          ROOT_HIERARCHY_ID,
          'initial',
          mapLayerTemplateId,
          mapObjectTemplateId
        );

        const { stashedEntitiesMap, entitiesMap } = getFilteredEntitiesMap(
          state.stash.entitiesMap,
          state.entitiesMap,
          filteredEntitiesMap,
          isFiltering
        );

        state.stash = { entitiesMap: stashedEntitiesMap };
        state.entitiesMap = entitiesMap;
        state.hierarchy = { id: ROOT_HIERARCHY_ID, children: hierarchy ?? [] };
      })
      .addCase(getMapFilteredEntitiesThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapEntityChildrenThunk.fulfilled, (state, action) => {
        const { parentEntityIDs, mode } = action.meta.arg;
        const { results, hierarchy } = action.payload;
        const { id, children } = hierarchy?.[0] ?? {
          id: ROOT_HIERARCHY_ID,
          children: [],
        };
        const currentEntityId = parentEntityIDs?.[0] ?? id;
        const mapLayerTemplateId =
          state.predefinedTemplates['mapLayer']?.template.id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates['mapObject']?.template.id || 0;
        const entityChildrenMap = getEntitiesMap(
          results,
          currentEntityId,
          mode,
          mapLayerTemplateId,
          mapObjectTemplateId
        );

        state.entitiesMap = {
          ...state.entitiesMap,
          ...entityChildrenMap,
        };

        state.hierarchy = updateHierarchyProperty(
          state.hierarchy,
          currentEntityId,
          'children',
          children
        );
      })
      .addCase(getMapEntityChildrenThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(upsertEntityThunk.fulfilled, (state, action) => {
        const { id, parentEntityID } = action.meta.arg;
        const prevEntity = state.entitiesMap[String(id)];
        const entity = action.payload;
        const mapLayerTemplateId =
          state.predefinedTemplates['mapLayer']?.template.id || 0;
        const mapObjectTemplateId =
          state.predefinedTemplates['mapObject']?.template.id || 0;

        if (prevEntity) {
          // updating existing entity contents
          state.entitiesMap = updateMapEntity(state.entitiesMap, entity);
        } else {
          // adding new entity to entity map
          const mapEntity = getInitialMapEntity(
            entity,
            mapLayerTemplateId,
            mapObjectTemplateId,
            parentEntityID
          );

          // new entities must be active by default
          mapEntity.state.active = true;

          state.entitiesMap = {
            ...state.entitiesMap,
            [String(entity.id)]: mapEntity,
          };

          // adding new hierarchy node to hierarchy
          state.hierarchy = processHierarchy(
            state.hierarchy,
            parentEntityID || ROOT_HIERARCHY_ID,
            (hierarchy) => ({
              ...hierarchy,
              children: [...hierarchy.children, createHierarchyNode(entity.id)],
            })
          );
        }

        notify.success(
          prevEntity
            ? successMessages.ENTITY_UPDATE_SUCCESS
            : successMessages.ENTITY_CREATION_SUCCESS
        );
      })
      .addCase(upsertEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(deleteEntityThunk.fulfilled, (state, action) => {
        const deletedEntityID = action.meta.arg;
        const mapEntity = state.entitiesMap[String(deletedEntityID)];

        if (mapEntity?.parentIDs.length > 0) {
          state.hierarchy = deleteNodeFromHierarchy(
            state.hierarchy,
            deletedEntityID,
            mapEntity.parentIDs[0]
          );
        }

        const { [String(deletedEntityID)]: _, ...newState } = state.entitiesMap;
        state.entitiesMap = newState;
      })
      .addCase(deleteEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(relinkEntityThunk.fulfilled, (state, action) => {
        const { entityId, oldParentEntityId, newParentEntityId } =
          action.meta.arg;

        const updatedEntitiesMap = moveMapEntity(
          state.entitiesMap,
          entityId,
          oldParentEntityId,
          newParentEntityId
        );

        const updatedHierarchy = moveHierarchyNode(
          state.hierarchy,
          entityId,
          oldParentEntityId,
          newParentEntityId
        );

        state.entitiesMap = updatedEntitiesMap;
        state.hierarchy = updatedHierarchy;
      })
      .addCase(relinkEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(addMediaToEntityThunk.fulfilled, (state, action) => {
        const entity = action.payload;
        state.entitiesMap = updateMapEntity(state.entitiesMap, entity);
      })
      .addCase(addMediaToEntityThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getMapEntityCountersThunk.fulfilled, (state, action) => {
        state.entityCountersMap = state.entityCountersMap = {
          ...state.entityCountersMap,
          ...getEntityCountersMap(action.payload),
        };
      })
      .addCase(getMapEntityCountersThunk.rejected, (state, action) => {
        notify.error(action.payload?.message);
      })
      .addCase(getPredefinedTemplateThunk.fulfilled, (state, action) => {
        const response = action.payload;
        state.predefinedTemplates[response.template.title] = response;
      })
      .addCase(getPredefinedTemplateThunk.rejected, () => {
        notify.error(errorMessages.GET_PREDEFINED_TEMPLATES_ERROR);
      }),
});

export const { actions: mapEntitiesActions, reducer: mapEntitiesReducer } =
  mapEntitiesSlice;
