import { FC, useEffect, useState } from 'react';
import { predefinedTemplates } from 'constants/entities';
import { MAP_LAYER_NAME_LIMIT } from 'constants/map';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ISelectOption } from 'interfaces';
import {
  relinkEntityThunk,
  upsertEntityThunk,
} from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/actions';
import { predefinedTemplateIdSelector } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/selectors';
import { getEmptyMapLayerEntity } from 'store/slices/mapV2/tabsReducer/layersReducer/mapEntitiesSlice/utils';
import { MapEntity } from 'types';

import { Button, Modal, Select, TextInput } from 'components/ui';
import { getSelectOptionsFromSearch } from 'utils/entity';

import { EntityNode } from '../Tabs/Layers/types';

export interface LayerDetailsProps {
  parentId?: number;
  layer?: EntityNode;
  onClose: () => void;
}

export const LayerDetails: FC<LayerDetailsProps> = ({
  onClose,
  layer,
  parentId: initialParentId = 0,
}) => {
  const dispatch = useAppDispatch();
  const mapObjectTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_OBJECT)
    ) || 0;
  const mapLayerTemplateId =
    useAppSelector((state) =>
      predefinedTemplateIdSelector(state, predefinedTemplates.MAP_LAYER)
    ) || 0;

  const [loading, setLoading] = useState(false);
  const [layerOptions, setLayerOptions] = useState<ISelectOption[]>([]);

  const [layerEntity, setLayerEntity] = useState<MapEntity>(
    layer
      ? layer.data.entity
      : getEmptyMapLayerEntity(mapLayerTemplateId, mapObjectTemplateId)
  );
  const [parentId, setParentId] = useState(
    layer && layer.data.entity.parentIDs.length
      ? layer.data.entity.parentIDs[0]
      : initialParentId
  );

  const handleSave = async () => {
    const prevParentID =
      layerEntity.parentIDs.length && layerEntity.parentIDs[0];

    let payload = layerEntity.entity;

    if (!prevParentID && parentId) {
      payload = {
        ...payload,
        parentEntityID: parentId,
      };
    }

    setLoading(true);

    await dispatch(upsertEntityThunk(payload))
      .then(async (r) => {
        if (r.payload && 'id' in r.payload) {
          const targetId = r.payload.id;

          setLayerEntity({
            ...layerEntity,
            entity: { ...layerEntity.entity, id: targetId },
          });

          if (prevParentID && parentId) {
            const relinkParams = {
              entityId: targetId,
              oldParentEntityId: prevParentID,
              newParentEntityId: parentId,
            };
            await dispatch(relinkEntityThunk(relinkParams));

            setLayerEntity({ ...layerEntity, parentIDs: [parentId] });
          }

          onClose();
        }
      })
      .finally(() => setLoading(false));
  };

  const handleUpdateLayerTitle = (title: string) =>
    setLayerEntity({
      ...layerEntity,
      entity: { ...layerEntity.entity, title },
    });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getSelectOptionsFromSearch(
        mapObjectTemplateId,
        setLayerOptions,
        [mapLayerTemplateId],
        [layerEntity.entity.id || []].flat()
      ).finally(() => setLoading(false));
    };

    fetchData();
  }, [layerEntity.entity.id]);

  return (
    <Modal width={460} keyboard isBlurred onClose={onClose}>
      <div className="w-full bg-dark border border-solid border-tpg_light rounded-[10px] p-12">
        <div className="tpg-h4 flex justify-center pb-4">
          {layerEntity.entity.id ? 'Редактирование' : 'Создание'}
        </div>
        <TextInput
          value={layerEntity.entity.title}
          placeholder="Название проекта"
          autoFocus
          maxLength={MAP_LAYER_NAME_LIMIT}
          onChange={handleUpdateLayerTitle}
          className={'w-full'}
        />
        <div className="pb-4 pt-3">
          <Select
            withSearch
            searchPlaceholder="Начните поиск"
            value={parentId}
            options={layerOptions}
            onSelect={setParentId}
            theme="light"
            isExpandable
            disabled={loading}
          />
        </div>
        <Button
          className="w-full"
          disabled={!layerEntity.entity.title}
          title="Сохранить"
          onClick={handleSave}
          isLoading={loading}
        />
      </div>
    </Modal>
  );
};
