import { Layer, Source } from 'react-map-gl';
import { demMapLayer, demMapSource } from 'configs/map/demMap';
import { LINE_LAYER } from 'constants/map';
import { useAppSelector } from 'hooks';
import { useMapRef } from 'hooks/map';
import { typesSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { getProperOpacity, getSafeBeforeId } from 'utils';

export const DemSource = () => {
  const mapTypes = useAppSelector(typesSelector);
  const { mapRef } = useMapRef();

  const isShowDemMap = mapTypes.demMap.active;

  if (!isShowDemMap) {
    return null;
  }

  return (
    <Source {...demMapSource}>
      <Layer
        beforeId={getSafeBeforeId(mapRef.current, LINE_LAYER)}
        {...demMapLayer}
        type="raster"
        paint={{ 'raster-opacity': getProperOpacity(mapTypes.demMap) }}
      />
    </Source>
  );
};
