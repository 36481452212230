import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reducersNames } from 'constants/reducers';
import { IAuthState, ILoginResponse } from 'interfaces';

import {
  getTileToken,
  getToken,
  removeToken,
  setTileToken,
  setToken,
} from 'utils';

import { getPrivilegesThunk, loginThunk } from './actions';

const initialState: IAuthState = {
  pending: false,
  error: null,
  accessToken: getToken(),
  tileToken: getTileToken(),
  privileges: null,
};

const authSlice = createSlice({
  name: reducersNames.AUTH,
  initialState,
  reducers: {
    logout(state) {
      state.accessToken = null;
      state.privileges = null;
      removeToken();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loginThunk.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          if (action.payload.access_token) {
            setToken(action.payload.access_token);
            state.accessToken = action.payload.access_token;
            setTileToken(action.payload.tile_token);
            state.tileToken = action.payload.tile_token;
          }
          state.pending = false;
        }
      )
      .addCase(loginThunk.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state) => {
        state.pending = false;
        state.error = 'Неверный логин или пароль';
      })
      .addCase(getPrivilegesThunk.fulfilled, (state, action) => {
        state.privileges = action.payload;
      });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
