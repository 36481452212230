import { appRoutes } from './routes';

export const navs = [
  {
    link: appRoutes.MONITORING,
    title: 'Лента',
  },
  // hide in prod
  // {
  //   link: appRoutes.ENTITIES,
  //   title: 'Таблица',
  // },
  {
    link: appRoutes.MAP,
    title: 'Карта',
  },
  {
    link: appRoutes.REPORTS,
    title: 'Отчёты',
  },
  {
    link: appRoutes.DUMPS,
    title: 'Утечки',
  },
];
