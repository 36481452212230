import { useCallback, useState } from 'react';
import { Popup } from 'react-map-gl';
import { MapMouseEvent, MapTouchEvent } from '@mapbox/mapbox-gl-draw';
import area from '@turf/area';
import { Feature, lineString, polygon } from '@turf/helpers';
import length from '@turf/length';
import { MAP_MEASURE_MODES } from 'constants/mapControl';
import { useAppSelector } from 'hooks';
import { useMapDrawEventSubscription } from 'hooks/useMapDrawEventsSubscription';
import { drawModeSelector } from 'store/slices/mapV2/mapReducer/toolsReducer/drawSlice/selectors';

import { calculateFeatureCenter, isFeatureValid } from 'utils';
import { getMeasureString } from 'utils/measurements';

import './style.scss';

const getMeasure = (e: MapMouseEvent | MapTouchEvent, mode: string) => {
  // MapMouseEvent.featureTarget fields real properties differ from DrawFeature type
  const drawFeature = e.featureTarget as unknown as Feature<any, any>;

  if (!e.featureTarget || !isFeatureValid(drawFeature)) {
    return 0;
  }

  switch (mode) {
    case MAP_MEASURE_MODES.measure_line_string: {
      const measure =
        drawFeature.geometry.type === 'LineString'
          ? length(lineString(drawFeature.geometry.coordinates), {
              units: 'meters',
            })
          : 0;

      return measure ? getMeasureString(measure) : '';
    }
    case MAP_MEASURE_MODES.measure_polygon: {
      const measure =
        drawFeature.geometry.type === 'Polygon'
          ? area(polygon(drawFeature.geometry.coordinates))
          : 0;

      return measure ? getMeasureString(measure, true) : '';
    }
    case MAP_MEASURE_MODES.measure_circle: {
      const measure = drawFeature?.properties?.user_radiusInM;

      return measure ? getMeasureString(measure) : '';
    }
    default:
      return 0;
  }
};

const getPopupCoordinates = (
  e: MapMouseEvent | MapTouchEvent,
  mode: string
) => {
  const drawFeature = e.featureTarget as unknown as Feature<any, any>;

  if (!drawFeature || !isFeatureValid(drawFeature)) {
    return [0, 0];
  }

  switch (mode) {
    case MAP_MEASURE_MODES.measure_line_string:
      return calculateFeatureCenter(drawFeature);
    case MAP_MEASURE_MODES.measure_polygon:
      return calculateFeatureCenter(drawFeature);
    case MAP_MEASURE_MODES.measure_circle:
      return [e.lngLat.lng, e.lngLat.lat];
    default:
      return [0, 0];
  }
};

export const MeasureSource = () => {
  const measureMode = useAppSelector(drawModeSelector);
  const [measure, setMeasure] = useState('');
  const [popupCoordinates, setPopupCoordinates] = useState([0, 0]);

  const handleMouseMove = useCallback(
    (e: MapMouseEvent) => {
      const measure = getMeasure(e, measureMode);
      const popupCoordinates = getPopupCoordinates(e, measureMode);

      measure && setMeasure(measure);
      popupCoordinates[0] &&
        popupCoordinates[1] &&
        setPopupCoordinates(popupCoordinates);
    },
    [measureMode]
  );

  const handleModeChange = useCallback(() => {
    setMeasure('');
    setPopupCoordinates([0, 0]);
  }, []);

  const handleTap = useCallback(
    (e: MapTouchEvent) => {
      const measure = getMeasure(e, measureMode);
      console.log(measureMode);
      const popupCoordinates = getPopupCoordinates(e, measureMode);

      measure && setMeasure(measure);
      popupCoordinates[0] &&
        popupCoordinates[1] &&
        setPopupCoordinates(popupCoordinates);
    },
    [measureMode]
  );

  useMapDrawEventSubscription({
    onMouseMove: handleMouseMove,
    onModeChange: handleModeChange,
    onTap: handleTap,
  });

  if (
    !measure ||
    popupCoordinates[0] === null ||
    popupCoordinates[1] === null
  ) {
    return null;
  }

  return (
    <Popup
      key={measure}
      className="measure-popup"
      anchor="bottom-left"
      closeButton={false}
      offset={8}
      longitude={popupCoordinates[0]}
      latitude={popupCoordinates[1]}
    >
      {measure}
    </Popup>
  );
};
