import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ASTRA_COLORS, authRoutes } from 'constants/routes';
import { useAppDispatch } from 'hooks';
import { ReactComponent as MenuIcon } from 'images/newIcons/menu.svg';
import { authActions } from 'store';

import { Modal } from 'components/ui';
import ConfirmModal from 'components/ui/Modal/ConfirmModal';

import './style.scss';

export interface BurgerMenuItem {
  title: string;
  onClick: () => void;
}

interface BurgerMenuProps {
  isOpen: boolean;
  items: BurgerMenuItem[];
  handleCloseModal: () => void;
  toggleMenu: () => void;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({
  isOpen,
  items,
  toggleMenu,
  handleCloseModal,
}) => {
  const [isExitModal, setExitModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fillColor = isOpen ? ASTRA_COLORS.BRIGHT_PRODUCT : '#fff';

  const renderMenuOptions = () => {
    return (
      <Modal
        keyboard
        width={300}
        onClose={handleCloseModal}
        style={{ top: '24px', left: '24px', position: 'absolute' }}
        isBlurred
      >
        <div className="burger-menu">
          <div className="tpg-b1 pb-2">Здравье желаю, DOOM SLAYER</div>
          <ul className="tpg-input">
            {items.map((item) => (
              <li
                key={item.title}
                className="burger-menu__options"
                onClick={() => {
                  item.onClick();
                  handleCloseModal();
                }}
              >
                {item.title}
              </li>
            ))}
            <li
              className="burger-menu__options"
              onClick={() => {
                setExitModal(true);
                handleCloseModal();
              }}
            >
              Выход
            </li>
          </ul>
        </div>
      </Modal>
    );
  };

  const handleExit = () => {
    dispatch(authActions.logout());
    setTimeout(navigate, 100, authRoutes.SIGN_IN);
  };

  return (
    <>
      <div className="flex items-center cursor-pointer" onClick={toggleMenu}>
        <MenuIcon className="burger-menu__icon" fill={fillColor} />
      </div>
      {isOpen && renderMenuOptions()}
      {isExitModal && (
        <ConfirmModal
          title="Вы уверены, что хотите выйти?"
          description="У нас ещё много работы и врагов."
          confirmText="Да, я хочу выйти"
          onConfirm={handleExit}
          onClose={() => {
            setExitModal(false);
          }}
        />
      )}
    </>
  );
};
