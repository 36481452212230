import { createSlice } from '@reduxjs/toolkit';

import { TABS_REDUCER_NAMES } from '../constants';

import {
  ImageryState,
  SetObjectsAction,
  SetVisibleObjectsAction,
} from './types';

const initialState: ImageryState = {
  objects: [],
  visibleObjects: [],
};

const imagerySlice = createSlice({
  name: TABS_REDUCER_NAMES.IMAGERY,
  initialState: initialState,
  reducers: {
    setObjects(state, action: SetObjectsAction) {
      const { objects } = action.payload;

      state.objects = objects;
    },
    setVisibleObjects(state, action: SetVisibleObjectsAction) {
      const { visibleObjects } = action.payload;

      state.visibleObjects = visibleObjects;
    },
  },
  extraReducers: (builder) => builder,
});

export const { actions: imageryActions, reducer: imageryReducer } =
  imagerySlice;
