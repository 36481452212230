import React, { FC, ReactNode, useMemo, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as Down } from 'images/newIcons/down.svg';
import { ReactComponent as Plus } from 'images/newIcons/plus.svg';
import { ReactComponent as Up } from 'images/newIcons/up.svg';

import './EntityFields.scss';

interface EntityFieldsGroupProps {
  children: ReactNode;
  title: string;
  isFieldsExpandable?: boolean;
  childrenWrapperMixin?: string;
  headerClassName?: string;
}

const EntityFieldsGroup: FC<EntityFieldsGroupProps> = ({
  children,
  title,
  isFieldsExpandable = false,
  childrenWrapperMixin,
  headerClassName,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandClick = () => setIsExpanded(!isExpanded);

  const Chevron = useMemo(() => (isExpanded ? Up : Down), [isExpanded]);

  return (
    <div className="flex flex-col items-start gap-4 self-stretch">
      <div className="flex items-start self-stretch">
        <div
          className={`${headerClassName} flex justify-between items-center flex-[1_0_0] cursor-pointer`}
          onClick={handleExpandClick}
        >
          <div className="tpg-b2 text-tpg_title select-none">{title}</div>
          <div className="flex items-center gap-1">
            {/*TODO: add field extension logic*/}
            {isFieldsExpandable ?? (
              <Plus
                className="icon-container"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            )}
            <Chevron className="icon-container" onClick={handleExpandClick} />
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className={cn('flex flex-col self-stretch', childrenWrapperMixin)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default EntityFieldsGroup;
