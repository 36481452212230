import React, { FC, useCallback, useEffect, useState } from 'react';

import { OpacitySlider } from 'components/OpacitySlider';
import { Switch } from 'components/ui';

import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useMapRef } from '../../../../hooks/map';
import altImagery from '../../../../images/newIcons/altImagery.svg';
import { IImageryObject, IMediaFile } from '../../../../interfaces';
import { mapActions } from '../../../../store';
import { authSelector } from '../../../../store/slices/auth/selectors';
import { imagerySelector } from '../../../../store/slices/map/selectors';
import { getFormattedDate } from '../../../../utils';
import {
  addTileTokenToUrl,
  constructTilesetUrl,
} from '../../../../utils/tileserver';
import PreviewMediaFiles from '../../../PreviewMediaFiles';
import { TooltipWrap } from '../../../ui/Tooltip';

import './style.scss';

interface TImageryItemProps {
  value: IImageryObject;
}

const imageryPreviewDefaultUrl =
  'https://astra-ui-api.hb.bizmrg.com/media_pkif1srr_Indian_Ocean_countries_satellite_c053aa2c-79b5-408f-955f-9f02a2a766ee-prv.jpg';

const ImageryItem: FC<TImageryItemProps> = ({ value }) => {
  const { tileToken } = useAppSelector(authSelector);
  const { mapRef } = useMapRef();
  const dispatch = useAppDispatch();
  const { visibleImageryObjects } = useAppSelector(imagerySelector);

  const [opacity, setOpacity] = useState(100);
  const [active, setActive] = useState(false);

  const previewUrl =
    value.preview_url && tileToken
      ? addTileTokenToUrl(constructTilesetUrl(value.preview_url), tileToken)
      : imageryPreviewDefaultUrl;
  const metadata = [getFormattedDate(value.scene_timestamp), value.description]
    .filter((v) => v !== undefined)
    .join(', ');

  const nameParId = `p-title-imagery-${value.id}`;
  const metadataParId = `p-metadata-imagery-${value.id}`;

  const handleOpacityChange = (opacity: number | number[]) => {
    setOpacity(opacity as number);
    dispatch(
      mapActions.updateVisibleImageryObjects({
        ...value,
        opacity: opacity as number,
      })
    );
  };

  const toggleItemState = (state: boolean) => {
    setActive(state);
    if (state) {
      dispatch(mapActions.addVisibleImageryObjects(value));
    } else {
      dispatch(mapActions.removeVisibleImageryObjects(value));
    }
  };

  useEffect(() => {
    const currentItemIsVisible = () =>
      visibleImageryObjects.find((v) => v.id === value.id);
    if (!active && currentItemIsVisible()) {
      setActive(true);
    } else if (active && !currentItemIsVisible()) {
      setActive(false);
    }
  }, [visibleImageryObjects]);

  const handleFlyToScene = useCallback(
    (value: IImageryObject) => {
      if (!active) {
        toggleItemState(true);
      }
      const [lat, long, zoom] = value.center;
      mapRef.current?.flyTo({ zoom, center: [lat, long] });
    },
    [active]
  );

  const mockImageryMedia: IMediaFile = {
    id: 291,
    url: previewUrl,
    type: 'image',
    altMedia: {
      src: `${altImagery}`,
      className: 'px-[9px] py-3 bg-bright',
    },
  };

  return (
    <div className="flex flex-row select-none max-w-full">
      <div
        className="imagery-body__items__row__preview"
        onDoubleClick={() => handleFlyToScene(value)}
      >
        <PreviewMediaFiles
          visible={1}
          showFullImage={false}
          files={[mockImageryMedia]}
        />
      </div>
      <div className="flex flex-col justify-center h-12 w-[calc(100%-64px)] gap-1">
        <div className="flex flex-row justify-between w-full h-6 items-center">
          <TooltipWrap title={value.name} idToCheckOverflow={nameParId}>
            <p
              id={nameParId}
              className="tpg-b2 overflow-hidden text-ellipsis whitespace-nowrap shrink"
              style={{ maxWidth: '85%' }}
            >
              {value.name}
            </p>
          </TooltipWrap>
          <div className="self-center">
            <Switch
              checked={active}
              onChange={() => toggleItemState(!active)}
            />
          </div>
        </div>
        <div className="w-full h-5 max-h-5 overflow-hidden">
          {active ? (
            <OpacitySlider opacityCallback={handleOpacityChange} />
          ) : (
            <TooltipWrap title={metadata} idToCheckOverflow={metadataParId}>
              <p
                id={metadataParId}
                className="tpg-c2 text-tpg_base h-5 max-w-[311px] overflow-hidden text-ellipsis whitespace-nowrap"
              >
                {metadata}
              </p>
            </TooltipWrap>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageryItem;
