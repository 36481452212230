import { FC, memo, ReactNode } from 'react';
import { MarkerProps } from 'react-map-gl/dist/esm/components/marker';
import { ReactComponent as MarkerIcon } from 'images/icons/map-marker.svg';

import MarkerWithTouch from '../MarkerWithTouch';

interface ContextProps extends MarkerProps {
  icon?: ReactNode | null;
  onContextMenu?: () => void;
}

const CustomMarker: FC<ContextProps> = ({
  longitude,
  latitude,
  color = '#f5Eb8d',
  icon = null,
  onClick,
  onContextMenu,
  ...props
}) => (
  <div onContextMenu={onContextMenu}>
    <MarkerWithTouch
      {...props}
      longitude={longitude}
      latitude={latitude}
      onClick={onClick}
    >
      {icon || <MarkerIcon className="icon" fill={color} />}
    </MarkerWithTouch>
  </div>
);

export default memo(CustomMarker);
