import { memo, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableStateSnapshot,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import cn from 'classnames';
import { useAppDispatch } from 'hooks';
import { ReactComponent as Play } from 'images/icons/play.svg';
import { ReactComponent as Close } from 'images/newIcons/close.svg';
import { IMediaFile } from 'interfaces';

import { safeSortFiles } from 'utils/media';

import './style.scss';

type PreviewMediaFilesSize = 's' | 'm';

interface MediaFilesProps {
  files: IMediaFile[];
  mediaOrder?: number[];
  onClick?: (files: IMediaFile[]) => void;
  onDelete?: (item: IMediaFile) => void;
  visible?: number;
  showFullImage?: boolean;
  isExpanded?: boolean;
  isDelete?: boolean;
  size?: PreviewMediaFilesSize;
  measureCell?: () => void;
  useDnD?: boolean;
  onMediaReorder?: (media: IMediaFile[]) => void;
}

const getDnDStyle = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot
) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  if (snapshot.dropAnimation) {
    const { moveTo, curve } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x - 5}px, ${moveTo.y}px)`;

    return {
      ...style,
      transform: `${translate}`,
      transition: `all ${curve} 0.4s`,
    };
  }
};

const PreviewMediaFiles = ({
  files,
  mediaOrder,
  onClick,
  onDelete,
  measureCell,
  visible = 3,
  showFullImage = true,
  isExpanded = false,
  isDelete = false,
  size = 's',
  useDnD = false,
  onMediaReorder = () => {},
}: MediaFilesProps) => {
  const [expanded, setExpanded] = useState(false);
  const [orderedFiles, setOrderedFiles] = useState<IMediaFile[]>([]);
  const dispatch = useAppDispatch();
  const visibleOrderedFiles = useMemo(
    () => orderedFiles.slice(0, expanded ? orderedFiles.length : visible),
    [orderedFiles, expanded]
  );

  const quantityFiles = useMemo(
    () =>
      Math.sign(files.length - visible) !== -1 ? files.length - visible : null,
    [visibleOrderedFiles, visible]
  );

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = [...orderedFiles];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);

    reorderedItems.splice(result.destination.index, 0, movedItem);
    setOrderedFiles(reorderedItems);
    onMediaReorder(reorderedItems);
  };

  const handleClick = (event: React.MouseEvent) => {
    if (event.defaultPrevented) return;

    event.preventDefault();
    onClick && onClick(files);
    event.stopPropagation();
  };

  const handleMore = (event: React.MouseEvent) => {
    if (event.defaultPrevented) return;

    event.preventDefault();

    if (isExpanded) {
      setExpanded(true);
    }

    event.stopPropagation();
  };

  const altImageHandle = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    altMedia: { src: string; className?: string } | undefined
  ) => {
    if (altMedia && event.currentTarget.src !== altMedia.src) {
      event.currentTarget.src = altMedia.src;
      event.currentTarget.className = altMedia.className ?? '';
    }
  };

  const renderItem = (
    item: IMediaFile,
    idx: number,
    array: IMediaFile[],
    isSlide = false
  ) => (
    <Draggable
      key={`preview-image-${idx}`}
      draggableId={`preview-image-${idx}`}
      index={idx}
      isDragDisabled={!useDnD}
    >
      {(provided, snapshot) => (
        <li
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={getDnDStyle(provided.draggableProps.style, snapshot)}
          key={`preview-image-${item.url}`}
          className={cn(`preview__list-item-${size}`, {
            preview__slide: isSlide,
          })}
        >
          {item.type === 'video' ? (
            <Play className="preview__play-icon" />
          ) : (
            <img
              src={item.url}
              onError={(e) => altImageHandle(e, item.altMedia)}
              alt="медиафайл"
            />
          )}
          {isDelete && onDelete && (
            <div
              className={`preview__close-icon-${size}`}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(item);
              }}
            >
              <Close />
            </div>
          )}
        </li>
      )}
    </Draggable>
  );

  useEffect(() => {
    setOrderedFiles(safeSortFiles(files, mediaOrder));
  }, [expanded, files, mediaOrder]);

  useEffect(() => {
    measureCell && measureCell();
  }, [visibleOrderedFiles]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="preview">
        <Droppable
          droppableId="useless-trash-because-crash-otherwise"
          isDropDisabled
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              onClick={showFullImage ? handleClick : undefined}
            >
              {showFullImage &&
                visibleOrderedFiles[0] &&
                renderItem(visibleOrderedFiles[0], 0, [], true)}
            </div>
          )}
        </Droppable>
        {((showFullImage && visibleOrderedFiles.length > 1) ||
          !showFullImage) && (
          <div className="flex flex-row">
            <Droppable
              droppableId="list"
              direction="horizontal"
              isDropDisabled={!useDnD}
            >
              {(provided) => (
                <ul
                  className={cn('preview__list', {
                    preview__list_expanded: expanded,
                  })}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  onClick={handleClick}
                >
                  {visibleOrderedFiles.map(renderItem)}
                </ul>
              )}
            </Droppable>
            {!!quantityFiles && (
              <div
                className={cn(
                  `preview__list-item-${size}__more bg-light ml-3`,
                  {
                    'preview__list-item-s__more-hidden':
                      expanded && size === 's',
                    'preview__list-item-m__more-hidden':
                      expanded && size === 'm',
                  }
                )}
                onClick={handleMore}
              >
                <span className="text-tpg_base tpg-b1">+{quantityFiles}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

export default memo(PreviewMediaFiles);
