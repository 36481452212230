import { FC } from 'react';

import { IObjectPopupProps, ObjectPopup } from 'components/Map/ObjectPopup';
type TObjectPreviewPopup = IObjectPopupProps;

export const ObjectPreviewPopup: FC<TObjectPreviewPopup> = ({
  children,
  ...props
}) => (
  <ObjectPopup
    anchor="bottom"
    tip={false}
    closeButton={false}
    keyboard
    maxWidth="521"
    className="w-[521px] bg-dark border border-tpg_light border-solid rounded-[10px]"
    {...props}
  >
    <div className="w-full overflow-hidden rounded-[inherit]">{children}</div>
  </ObjectPopup>
);
