import { FC, memo } from 'react';
import cn from 'classnames';
import { ReactComponent as Arrow } from 'images/newIcons/down.svg';

interface EntitiesSidebarProps {
  isSidebarHidden: boolean;
  setSidebarHidden: (
    isSidebarHidden: boolean | ((prev: boolean) => boolean)
  ) => void;
}

const EntitiesSidebar: FC<EntitiesSidebarProps> = ({
  isSidebarHidden,
  setSidebarHidden,
}) => {
  return (
    <div
      className={cn(
        `absolute w-[474px] transition-[transform] duration-300 ease-in-out`,
        isSidebarHidden && 'translate-x-[-420px]'
      )}
    >
      <div className="flex flex-row justify-between items-center bg-light pl-4 pr-4 h-12">
        <span className="tpg-b1">
          Сущности
          <span className="tpg-b2 text-bright_product">&nbsp;базы знаний</span>
        </span>
        <div
          className="icon-container"
          onClick={() => setSidebarHidden((prev) => !prev)}
        >
          <Arrow
            className={cn(
              'arrow-icon',
              !isSidebarHidden ? 'rotate-90' : 'rotate-[-90deg]'
            )}
          />
        </div>
      </div>
      <div>
        <p className="p-4">Здесь когда-нибудь будет красивый сайдбар</p>
      </div>
    </div>
  );
};

export default memo(EntitiesSidebar);
