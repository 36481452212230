import { FC, useMemo } from 'react';
import { Popup } from 'react-map-gl';
import { PopupProps } from 'react-map-gl/dist/esm/components/popup';
import cn from 'classnames';
import { useAppSelector } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { showCustomCursorsSelector } from 'store/slices/mapV2/mapReducer/settingsSlice/selectors';

import { Button } from 'components/ui';

import './style.scss';

interface ContextPopupOption {
  title: string;
  onClick: () => void;
}

interface ContextPopupProps extends PopupProps {
  options: ContextPopupOption[];
  keyboard?: boolean;
  onClose: () => void;
}

export const ContextPopup: FC<ContextPopupProps> = ({
  options,
  longitude,
  latitude,
  offset = 0,
  anchor = 'top-left',
  maxWidth = '300px',
  className = 'popup-override',
  closeButton = false,
  keyboard = false,
  onClose,
  ...props
}) => {
  useEscapeDown(onClose, keyboard);

  const showCustomCursors = useAppSelector(showCustomCursorsSelector);

  const buttonClassName = useMemo(() => {
    return cn('map-popup-button', {
      'cursor-custom-default': showCustomCursors,
    });
  }, [showCustomCursors]);

  return (
    <Popup
      {...props}
      offset={offset}
      anchor={anchor}
      onClose={onClose}
      longitude={longitude}
      latitude={latitude}
      maxWidth={maxWidth}
      closeButton={closeButton}
      className={className}
      focusAfterOpen={false}
    >
      <div className="context-popup tpg-input" onClick={onClose}>
        {options.map((option, index) => (
          <Button
            key={index}
            title={option.title}
            onClick={option.onClick}
            className={buttonClassName}
            textClassName="tpg-input"
          />
        ))}
      </div>
    </Popup>
  );
};
